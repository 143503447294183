import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'calsoft-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  isLoading: Subject<boolean> = this.loaderService.isLoading;
  url: string;
  showLoader: boolean=true;
  constructor(
    private router:Router,
    private loaderService: LoaderService){}
  // color = 'primary';
  mode = 'indeterminate';

  ngOnInit(): void {

    this.url=this.router.url;

    if(this.router.url=='/category'){

      this.showLoader=false;
    }
  }

}

import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

import { SHA256 } from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class EcryptServiceService {

  secretKey;
    constructor() {
        this.secretKey = "abcd@ef#gh*I&jkl";
    }

    encrypt(value: any): string{
        
        const key = CryptoJS.enc.Utf8.parse(this.secretKey);
        const iv = CryptoJS.enc.Utf8.parse(this.secretKey);
        const  src = JSON.stringify(value);
        const encrypted = CryptoJS.AES.encrypt(src, key, {
            keySize: 16,    
            iv: iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }).toString();

      
        
        return  encrypted.replace(/\+/g,'xMl3Jk').replace(/\//g,'Por21Ld').replace(/\=/g,'Ml32');
      

       
    }

    decrypt(textToDecrypt: string){
        return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
    }

    key: string = "z!!!!!!!1sdfadsf56adf456asdfasdf";
    appProperties = {
      VALUES: {
        KEY: "MTIzNDU2Nzg5MEFCQ0RFRkdISUpLTE1O",
        IV: "MTIzNDU2Nzg="
      }
    }

    encryptionAES (msg) {
        // Encrypt
        const ciphertext = CryptoJS.AES.encrypt(msg.toString(), this.secretKey.trim());


        return ciphertext.toString().replace(/\+/g,'xMl3Jk').replace(/\//g,'Por21Ld').replace(/\=/g,'Ml32');
      }
    
      decryptionAES (msg) {
        // Decrypt
        msg=msg.toString().replaceAll('xMl3Jk','+');
        msg=msg.toString().replaceAll('Por21Ld','/');
        msg=msg.toString().replaceAll('Ml32','=');
        const bytes  = CryptoJS.AES.decrypt(msg, this.secretKey.trim());
        const plaintext = bytes.toString(CryptoJS.enc.Utf8);
        return plaintext;
      }

      decryptionAESNumber (msg) {
        // Decryp
        const bytes  = CryptoJS.AES.decrypt(msg, this.secretKey.trim());
        const plaintext = bytes(CryptoJS.enc.Utf8);
        return plaintext;
      }


      sha256(base64String){

        const hash = SHA256
        (base64String + "/pg/v1/pay" + '7887c825-8ad1-49e5-b49a-5133a2e8cec2');

        return hash;
      }

      



    
}

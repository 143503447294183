import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Constant } from '../constants/constant';
import { UserService } from './user.service';
import { ThisReceiver } from '@angular/compiler';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PincodeService {

  url = environment.serverAPI4 + "/api/config/pincode"; 
  url2 = environment.serverAPI4 + "/api/ig/config/pincode"; 
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
  ) { }

  searchPincode(query, Page: any, size: any, sort: any): Observable<Object> {

    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
      return this.http.get(`${this.url}/filter?search=${query}&page=${Page}&size=${size}&sort=${sort}`);
    }else{
    return this.http.get(`${this.url2}/filter?search=${query}&page=${Page}&size=${size}&sort=${sort}`);
    }
  }

  checkDuplicatePinCode(pinCode: any): Observable<Object> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
    return this.http.get(`${this.url}/pinCodeExists/${pinCode}`);
    }else{

      let cart=localStorage.getItem(Constant['CART_ID'])
      if(cart!=null){
        let headers = new HttpHeaders({
          [Constant['CART_ID']] :localStorage.getItem(Constant['CART_ID']),
        });
        let options = {
          headers: headers,
        };
        return this.http.get<any>(`${this.url2}/pinCodeExists/${pinCode}`,options);
      }else{
        this.router.navigate(['/']);
        
      }
     
    }

  }


  getPincode(Page: number, size: number, sort): Observable<Object> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
    
      return this.http.get(`${this.url}/all/site?page=${Page}&size=${size}&sort=${sort}`);
    }else{
      return this.http.get(`${this.url2}/all/site?page=${Page}&size=${size}&sort=${sort}`);
    }
  }



}

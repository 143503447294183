export class ColourConstant {
    public static COMMON_ACCENT = "#ff5722";
    public static COMMON_WARN = "#ff5722";
    // PRIMARY
    public static Indigo_PRIMARY = "#303f9f";
    public static Green_PRIMARY = "#2e7d32";
    public static Blue_PRIMARY = "#1976d2";
    public static Teal_PRIMARY = "#004d40";
    public static Pink_PRIMARY = "#c2185b";
    public static Red_PRIMARY = "#d32f2f";
    public static Purple_PRIMARY = "#6a1b9a";
    public static Yellow_PRIMARY = "#f57f17";
    //lightgreen
    public static lightGreen_PRIMARY = "#8BC34A";
    public static lightGreen100_PRIMARY = "#DCEDC8"; 
    public static lightGreen200_PRIMARY = "#C5E1A5"; 
    public static lightGreen300_PRIMARY = "#AED581"; 
    public static lightGreen400_PRIMARY = "#9CCC65"; 
    public static lightGreen500_PRIMARY = "#8BC34A"; 
    public static lightGreen600_PRIMARY = "#7CB342"; 
    public static lightGreen700_PRIMARY = "#689F38"; 
    public static lightGreen800_PRIMARY = "#558B2F"; 
    public static lightGreen900_PRIMARY = "#33691E";  
    //red
    public static red100_PRIMARY = "#FFCDD2";
    public static red200_PRIMARY = "#EF9A9A";
    public static red300_PRIMARY = "#E57373";
    public static red400_PRIMARY = "#EF5350";
    public static red500_PRIMARY = "#F44336";
    public static red600_PRIMARY = "#E53935";
    public static red700_PRIMARY = "#D32F2F";
    public static red800_PRIMARY = "#C62828";
    public static red900_PRIMARY = "#B71C1C";
    public static red1000_PRIMARY = "#FF8A80";
    public static red1200_PRIMARY = "#FF5252";
    public static red1300_PRIMARY = "#FF1744";
    public static red1700_PRIMARY = "#D50000";

    //pink
   
    public static pink100_PRIMARY = "#F8BBD0";
    public static pink200_PRIMARY = "#F48FB1";
    public static pink300_PRIMARY = "#F06292";
    public static pink400_PRIMARY = "#EC407A";
    public static pink500_PRIMARY = "#E91E63";
    public static pink600_PRIMARY = "#D81B60";
    public static pink700_PRIMARY = "#C2185B";
    public static pink800_PRIMARY = "#AD1457";
    public static pink900_PRIMARY = "#880E4F";

    

    //gray

    public static gray100_PRIMARY = "#F5F5F5";
    public static gray200_PRIMARY = "#EEEEEE";
    public static gray300_PRIMARY = "#E0E0E0";
    public static gray400_PRIMARY = "#BDBDBD";
    public static gray500_PRIMARY = "#9E9E9E";
    public static gray600_PRIMARY = "#757575";
    public static gray700_PRIMARY = "#616161";
    public static gray800_PRIMARY = "#424242";
    public static gray900_PRIMARY = "#212121";
    

    //orange

    public static orange100_PRIMARY = "#FFE0B2";
    public static orange200_PRIMARY = "#FFCC80";
    public static orange300_PRIMARY = "#FFB74D";
    public static orange400_PRIMARY = "#FFA726";
    public static orange500_PRIMARY = "#FF9800";
    public static orange600_PRIMARY = "#FB8C00";
    public static orange700_PRIMARY = "#F57C00";
    public static orange800_PRIMARY = "#EF6C00";
    public static orange900_PRIMARY = "#E65100";

    //green
    
    public static green100_PRIMARY = "#C8E6C9";
    public static green200_PRIMARY = "#A5D6A7";
    public static green300_PRIMARY = "#81C784";
    public static green400_PRIMARY = "#66BB6A";
    public static green500_PRIMARY = "#4CAF50";
    public static green600_PRIMARY = "#43A047";
    public static green700_PRIMARY = "#388E3C";
    public static green800_PRIMARY = "#2E7D32";
    public static green900_PRIMARY = "#1B5E20";

    // blue 

    public static blue100_PRIMARY = "#BBDEFB";
    public static blue200_PRIMARY = "#90CAF9";
    public static blue300_PRIMARY = "#64B5F6";
    public static blue400_PRIMARY = "#42A5F5";
    public static blue500_PRIMARY = "#2196F3";
    public static blue600_PRIMARY = "#1E88E5";
    public static blue700_PRIMARY = "#1976D2";
    public static blue800_PRIMARY = "#1565C0";
    public static blue900_PRIMARY = "#0D47A1";

    //indigo

    public static indigo100_PRIMARY = "#C5CAE9";
    public static indigo200_PRIMARY = "#9FA8DA";
    public static indigo300_PRIMARY = "#7986CB";
    public static indigo400_PRIMARY = "#5C6BC0";
    public static indigo500_PRIMARY = "#3F51B5";
    public static indigo600_PRIMARY = "#3949AB";
    public static indigo700_PRIMARY = "#303F9F";
    public static indigo800_PRIMARY = "#283593";
    public static indigo900_PRIMARY = "#1A237E";

    //teal
    public static teal100_PRIMARY = "#B2DFDB";
    public static teal200_PRIMARY = "#80CBC4";
    public static teal300_PRIMARY = "#4DB6AC";
    public static teal400_PRIMARY = "#26A69A";
    public static teal500_PRIMARY = "#009688";
    public static teal600_PRIMARY = "#00897B";
    public static teal700_PRIMARY = "#00796B";
    public static teal800_PRIMARY = "#00695C";
    public static teal900_PRIMARY = "#004D40";

    //lime
    public static lime100_PRIMARY = "#F0F4C3";
    public static lime200_PRIMARY = "#E6EE9C";
    public static lime300_PRIMARY = "#DCE775";
    public static lime400_PRIMARY = "#D4E157";
    public static lime500_PRIMARY = "#CDDC39";
    public static lime600_PRIMARY = "#C0CA33";
    public static lime700_PRIMARY = "#AFB42B";
    public static lime800_PRIMARY = "#9E9D24";
    public static lime900_PRIMARY = "#827717";

    //yellow
    public static yellow100_PRIMARY = "#FFF9C4";
    public static yellow200_PRIMARY = "#FFF59D";
    public static yellow300_PRIMARY = "#FFF176";
    public static yellow400_PRIMARY = "#FFEE58";
    public static yellow500_PRIMARY = "#FFEB3B";
    public static yellow600_PRIMARY = "#FDD835";
    public static yellow700_PRIMARY = "#FBC02D";
    public static yellow800_PRIMARY = "#F9A825";
    public static yellow900_PRIMARY = "#F57F17";

   //amber 
    public static amber100_PRIMARY = "#FFECB3";
    public static amber200_PRIMARY = "#FFE082";
    public static amber300_PRIMARY = "#FFD54F";
    public static amber400_PRIMARY = "#FFCA28";
    public static amber500_PRIMARY = "#FFC107";
    public static amber600_PRIMARY = "#FFB300";
    public static amber700_PRIMARY = "#FFA000";
    public static amber800_PRIMARY = "#FF8F00";
    public static amber900_PRIMARY = "#FF6F00";
 
    //lightblue
    public static lightblue100_PRIMARY = "#B3E5FC";
    public static lightblue200_PRIMARY = "#81D4FA";
    public static lightblue300_PRIMARY = "#4FC3F7";
    public static lightblue400_PRIMARY = "#29B6F6";
    public static lightblue500_PRIMARY = "#03A9F4";
    public static lightblue600_PRIMARY = "#039BE5";
    public static lightblue700_PRIMARY = "#0288D1";
    public static lightblue800_PRIMARY = "#0277BD";
    public static lightblue900_PRIMARY = "#01579B";

    //deeporange

    public static deeporange100_PRIMARY = "#FFCCBC";
    public static deeporange200_PRIMARY = "#FFAB91";
    public static deeporange300_PRIMARY = "#FF8A65";
    public static deeporange400_PRIMARY = "#FF7043";
    public static deeporange500_PRIMARY = "#FF5722";
    public static deeporange600_PRIMARY = "#F4511E";
    public static deeporange700_PRIMARY = "#E64A19";
    public static deeporange800_PRIMARY = "#D84315";
    public static deeporange900_PRIMARY = "#BF360C";

    //bluegray
    public static bluegray100_PRIMARY = "#CFD8DC";
    public static bluegray200_PRIMARY = "#B0BEC5";
    public static bluegray300_PRIMARY = "#90A4AE";
    public static bluegray400_PRIMARY = "#78909C";
    public static bluegray500_PRIMARY = "#607D8B";
    public static bluegray600_PRIMARY = "#546E7A";
    public static bluegray700_PRIMARY = "#455A64";
    public static bluegray800_PRIMARY = "#37474F";
    public static bluegray900_PRIMARY = "#263238";

    //brown
    public static brown100_PRIMARY = "#D7CCC8";
    public static brown200_PRIMARY = "#BCAAA4";
    public static brown300_PRIMARY = "#A1887F";
    public static brown400_PRIMARY = "#8D6E63";
    public static brown500_PRIMARY = "#795548";
    public static brown600_PRIMARY = "#6D4C41";
    public static brown700_PRIMARY = "#5D4037";
    public static brown800_PRIMARY = "#4E342E";
    public static brown900_PRIMARY = "#3E2723";

    //deeppurple

    public static deeppurple100_PRIMARY = "#D1C4E9";
    public static deeppurple200_PRIMARY = "#B39DDB";
    public static deeppurple300_PRIMARY = "#9575CD";
    public static deeppurple400_PRIMARY = "#7E57C2";
    public static deeppurple500_PRIMARY = "#673AB7";
    public static deeppurple600_PRIMARY = "#5E35B1";
    public static deeppurple700_PRIMARY = "#512DA8";
    public static deeppurple800_PRIMARY = "#4527A0";
    public static deeppurple900_PRIMARY = "#311B92";

    //purple 
    public static purple100_PRIMARY = "#E1BEE7";
    public static purple200_PRIMARY = "#CE93D8";
    public static purple300_PRIMARY = "#BA68C8";
    public static purple400_PRIMARY = "#AB47BC";
    public static purple500_PRIMARY = "#9C27B0";
    public static purple600_PRIMARY = "#8E24AA";
    public static purple700_PRIMARY = "#7B1FA2";
    public static purple800_PRIMARY = "#6A1B9A";
    public static purple900_PRIMARY = "#4A148C";

    //cyan 
    public static cyan100_PRIMARY = "#B2EBF2";
    public static cyan200_PRIMARY = "#80DEEA";
    public static cyan300_PRIMARY = "#4DD0E1";
    public static cyan400_PRIMARY = "#26C6DA";
    public static cyan500_PRIMARY = "#00BCD4";
    public static cyan600_PRIMARY = "#00ACC1";
    public static cyan700_PRIMARY = "#0097A7";
    public static cyan800_PRIMARY = "#00838F";
    public static cyan900_PRIMARY = "#006064";

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { Constant } from 'src/app/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class AuthGuestGuard  {
  constructor(private auth: UserService,
    private router: Router,
    private dialog: MatDialog,
    ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let basicAuthHeaderString = this.auth.getAuthenticatedToken();
    let username = this.auth.getAuthenticatedUser();
    let guestLogin=localStorage.getItem(Constant['GUEST_LOGIN'])
    if (basicAuthHeaderString||guestLogin=="1") {
      return true;
    } else {
     this.router.navigate(['/']);
      return false;
    }
  }
  
}

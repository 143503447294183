import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  position: any;
  constructor() { }

  getCurrentPosition(): Promise<any> {
    let options: any = {
      maximumAge: 3000,
      timeout: 1000,
      enableHeightAccuracy: false
    };
    
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          localStorage.setItem('locationDetected', 'true');
          localStorage.setItem('locationData', JSON.stringify(position));
          resolve(position);
        },
        (error) => {
          console.log(error.message);
          reject(error);
        },
        options
      );
    });
  }

}

<mat-toolbar  [ngClass]="color == 'white' ? 'wh' : 'primary'"
 class="primary app-toolbar" fxHide fxShow.lt-md style="border-bottom: 1px solid #cbc0c0;">
    <mat-toolbar-row style="padding: 0px;" fxLayout="row" fxLayoutAlign="space-between center">
        <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

            <button [ngStyle]="{'color':color=='white' ? 'black' : 'White' }" *ngIf="url=='/home';else elseBlock"
                class="responsive-toggle" mat-icon-button (click)="toggleSidebar()">
                <i style="font-size: 18px"  class="material-icons ">menu</i>
            </button>
            <ng-template #elseBlock>
                <!-- <button [ngStyle]="{'color':color=='white' ? 'black' : 'White' }" class="responsive-toggle "
                    mat-icon-button (click)="back()">
                    <i class="material-icons ">keyboard_arrow_left</i>
                </button> -->
              
                <button [ngStyle]="{'color':color=='white' ? 'black' : 'White' }" class="responsive-toggle " mat-icon-button (click)="back()">
                    <i class="material-icons ">keyboard_arrow_left</i>
                </button>

            </ng-template>
            <calsoft-logo  [sitename]="data.siteName" fxFlex [type]="'light'"></calsoft-logo>

        </section>

        <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2px">

            <!-- <div *ngIf="!userLoggedIn" (click)="SignIn('login')" class="white" fxLayoutAlign="start center">
                    <button mat-button   *ngIf="!userLoggedIn&&color=='white'">LOGIN/SIGNUP
                    </button>
                    <button mat-button matSuffix (click)="hide = !hide" >
                        Sign In
                        <mat-icon >person</mat-icon>
                        </button> 
                </div> -->

            <div fxLayout="row" (click)="SignIn()" fxLayoutAlign="center center" *ngIf="!userLoggedIn&&color=='white'">

                <button mat-button  [ngStyle]="{'padding':urlEnabled ? '1px' : '5px' }">
                    Sign In
                <mat-icon>person</mat-icon>
                </button>
            </div>
            <div class="white" (click)="SignIn('login')" *ngIf="!userLoggedIn&&color!='white'" fxLayout="row"
                fxLayoutAlign="center center">

                <button mat-button matSuffix (click)="hide = !hide">
                    Sign In
                    <mat-icon>person</mat-icon>
                </button>
            </div>

            <calsoft-HeaderUserProfileDropdown class="headerUser" [color]="color" *ngIf="userLoggedIn">
            </calsoft-HeaderUserProfileDropdown>
           

            <calsoft-cart-icon [color]="color=='white'?true:false"></calsoft-cart-icon>

        </section>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="color!='white'" style="padding: 0px 10px 10px 10px ; height: 43px;">
        <calsoft-search [scroll]="false"></calsoft-search>
    </mat-toolbar-row>

</mat-toolbar>


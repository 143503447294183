
    <mat-toolbar class="primary-color"    fxLayout="row" 
    fxLayoutAlign="space-between center">
      <span class="clrbg ">Available Pin Code</span>
      <button class="clrbg" matDialogClose="yes" mat-icon-button>
        <mat-icon >close</mat-icon>
      </button>
    </mat-toolbar>
    <mat-dialog-content  style=" MARGIN: 0PX;  max-height: 100%;
    padding: 0px;" >
      <div fxLayout="row"class="padding-l10 paddingr10" >
      <mat-form-field fxFlex class="filter-input" floatPlaceholder="never" fxFlex>
 
        <input     name="new-label"  [autocomplete]="'off'" (keyup)="searchFilterMain()" [formControl]="searchValue" matInput #filter placeholder="Search&hellip;">
    </mat-form-field>
    </div>
  
      <mat-divider></mat-divider>
      <div [ngClass]="elements>5?'example-container':'none'" class="overflowX">
  
        <table mat-table [dataSource]="pinCodeList" matSort (matSortChange)="sortData($event)">
    
          <ng-container matColumnDef="pinCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Pin Code </th>
            <td mat-cell *matCellDef="let row"> {{row.pinCode}}</td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
          <!-- Row shown when there is no matching data. -->
  
        </table>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="dialogBox">
      <div fxLayout="row" class="padding-10" fxLayoutAlign="end center">
        <mat-paginator class="paginator" (page)="getUpdate($event)" showFirstLastButtons [length]="pageList"
          [pageIndex]="page" [pageSize]="size" [pageSizeOptions]="pageSizeValue">
        </mat-paginator>
      </div>
    </mat-dialog-actions>
  
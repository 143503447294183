import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-nested-child',
  templateUrl: './nested-child.component.html',
  styleUrls: ['./nested-child.component.scss']
})
export class NestedChildComponent implements OnInit {

  @Input() list: any;
  @Input() type: any;
  display: boolean;
  @Input() data: any;
  
  constructor(private commonService: CommonService,) { }

  ngOnInit(): void {

    if (window.innerWidth < 1281) {
      this.display = false;
    }else{
      this.display=true
    }
    

  }

  goToCategory(menu) {

    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }
}

<div class="theme-container" id="catList">
    <mat-list fxHide fxShow.md fxShow.lt-md   
     lines="none"
      class="child-list" detail="false" no-padding menuClose>
  
      <div matRipple tappable style="padding-left: 15px"
  
        [ngClass]="{'section-active': openMenu, 'section': !openMenu}">
        <div (click)="open()" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-icon class="primary-icon">sticky_note_2</mat-icon>
          <mat-label fxFlex   class="itemLabel padding-5">
                  PHILOSOPHY
          </mat-label>
          <mat-icon   *ngIf="!openMenu" matSuffix >arrow_right</mat-icon>
          <mat-icon  class="iconClr" *ngIf="openMenu">arrow_drop_down</mat-icon>
        </div>
      </div>
      </mat-list>
      <div *ngIf="openMenu" menuClose>
        <mat-list *ngFor="let child of appPages2; let j = index"
         lines="none" class="child-list">
          <div tappable>
            <mat-label>

              <div fxLayout="row" fxLayoutAlign="space-between none" matRipple class="cName padding-10 padding-l40">
                <span (click)="goToUrl(child.url)" class="itemLabel" >
                     {{ child.title }}</span>
                
              </div>
            </mat-label>
  
          </div>
  
  
    </mat-list>
  
  </div>
  </div>
 
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  url2 = environment.serverAPI3 + "/api/wishlist";

  constructor(private http: HttpClient) { }

  addWishlist(wish): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url2}`, wish);
  }

  getWishlistById( ): Observable<Object> {
    return this.http.get(`${this.url2}/details`);

  }

  public geWishListProduct(): Observable<any> {

    return this.http.get(`${this.url2}/product/list`);

  }

  someMethod(): void {
    this.geWishListProduct().subscribe(
      (data) => {
        // Handle the data
        console.log('Wishlist data:', data);
      },
      (error) => {
        // Handle errors
        console.error('Error fetching wishlist data:', error);
      }
    );
  }


}

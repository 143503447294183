import { OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { CommonService } from "src/app/services/common.service";


@Pipe({
  name: "translate",
})
export class TranslatePipe implements PipeTransform,OnDestroy {
  result: any = [];
  finalValue: any;
  resultStatus: boolean;
  private _unsubscribeAll: Subject<any>;
  constructor(private commonService: CommonService) {
    this._unsubscribeAll = new Subject();
  }

  transform(value: string): any {
    let page = 0;
    let size = 10;
    let sortParam = "keyId,DESC";
    this.resultStatus = false;
    this.finalValue = value;
    this.resultStatus = false;
    let locale = this.commonService.getTranslateCountry();
    this.commonService
      .getTransaltedValue(value, locale, page, size, sortParam)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.result = res["content"];
        if (this.result.length > 0) {
          this.finalValue = res["content"][0]["translate"];

          this.resultStatus = true;
          return  this.finalValue
        }
      });


  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

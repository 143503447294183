import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from 'src/app/services/nav.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  @Input() categoryArray: any;
  public menuItems: any[] = [];
  megamenuPosition: string = 'bottom'; // Default position
    autoColumns: number;
  constructor(private router: Router, public navServices: NavService, private commonService: CommonService) {
    this.navServices.leftMenuItems.subscribe(categoryArray => this.categoryArray = categoryArray );
    this.router.events.subscribe((event) => {
    //  console.log(event)
      this.navServices.mainMenuToggle = false;
    });
  }
  ngOnInit(): void {
  }
  
  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item : any) {
    item.active = !item.active;
  }

  onHover(menuItem: any) {
    if(window.innerWidth > 1200 && menuItem){
       document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

  setMegamenuPosition() {
    // Adjust the breakpoint as needed
    this.megamenuPosition = window.innerWidth > 768 ? 'bottom' : 'top';
  }

  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);
  }

  calculateMegamenuPosition(categoryElement: HTMLElement): { [key: string]: string } {
    const windowHeight = window.innerHeight;
    const megamenuElement = categoryElement.querySelector('.megamenu') as HTMLElement;

    if (megamenuElement) {
      const megamenuWidth = megamenuElement.offsetWidth;
      const megamenuHeight = megamenuElement.offsetHeight;

      // Check if there is enough space below, otherwise adjust the position
      const isBelowSpaceEnough = windowHeight - (categoryElement.offsetTop + megamenuHeight) > 0;
      const topPosition = isBelowSpaceEnough
        ? (categoryElement.offsetTop + 10).toString() // Add a small offset at the top
        : (windowHeight - megamenuHeight - 10).toString(); // Add a small offset from the bottom

      const parentCategoryWidth = categoryElement.offsetWidth;
      const leftPosition = (categoryElement.offsetLeft + parentCategoryWidth).toString();

      return { 'top.px': topPosition, 'left.px': leftPosition };
    }

    return {};
  }

  
  calculateFlexValue(auto: any[], maxColumns: number): string {
    const totalItems = auto.length;
    const minColumns = Math.min(totalItems, maxColumns);
    
    if (minColumns > 0) {
      return `calc(100% / ${minColumns})`;
    } else {
      // Fallback value if there are no items
      return '100%';
    }
  }

  calculateWidth(categoryElement: HTMLElement): number {
    const megaMenuHeight = this.calculateMegaMenuHeight(categoryElement);
    
    // You can adjust this multiplier based on your requirements
    const widthMultiplier = 1.5; // Adjust this value as needed
  
    // Calculate width based on mega menu height
    return megaMenuHeight * widthMultiplier;
  }

  calculateMegaMenuHeight(categoryElement: HTMLElement): number {
  
    const megaMenu = categoryElement.querySelector('.megamenu') as HTMLElement;

    if (megaMenu) {
      const megaMenuStyles = getComputedStyle(megaMenu);
      const megaMenuHeight = megaMenu.offsetHeight + 
        parseInt(megaMenuStyles.marginTop) + 
        parseInt(megaMenuStyles.marginBottom);
      
      return megaMenuHeight;
    }
    
    return 0; // Return a default value if mega menu is not found
  }

  calculateColumnWidth(subcategories: any[]): string {
  
    const totalItems = subcategories.length;
    const autoColumns = Math.min(totalItems);
    return `calc(100% / ${autoColumns})`;
  }

  calculateColumnStyle(subcategories: any[]): any {
    const maxColumns = 4; // Set your maximum number of columns
    const totalItems = subcategories.length;
    const autoColumns = Math.min(totalItems, maxColumns);
    const columnHeight = 400; // Set your desired height for each column
    const maxRows = Math.ceil(totalItems / autoColumns);
    const maxContainerHeight = maxRows * columnHeight;
  
    return {
      'flex-basis': `calc(100% / ${autoColumns})`,
      'max-height': `${maxContainerHeight}px`,
      'overflow-y': 'auto',
    };
  }


}
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underScoreTransform'
})
export class UnderScoreTransformPipe implements PipeTransform {

  transform(value: string): string {
    var splittedText = value.replace('_',' ');
    splittedText = splittedText.replace(/BRS/g, '(');
    splittedText = splittedText.replace(/BREl3jk875/g, ')');;
    splittedText = value.replace('xMl3Jk','%');
 
    return splittedText;
  }
}

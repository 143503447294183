<div *ngIf="display">
<header class="header header-28 bg-transparent theme-container"

fxHide  fxShow.gt-md>

    <div class="header-top font-weight-normal text-light">
        <div [class]="containerClass">
            <div class="header-left">
                <div class="header-dropdown">
                    <div class="top-menu">
                        <div *ngIf="languageSelection==1">
                            <calsoft-language></calsoft-language>
                        </div>
                    </div>
                </div>

            </div>

            <div class="header-right header-dropdown" >
                <ul class="top-menu">
                    <li>
                        <ul>
                            <div class="dropdown" *ngIf="referer.includes('vgrowfarms.')">
                                <li class="dropbtn" style=" cursor: pointer; z-index: 1000;" ><a>PHILOSOPHY</a></li>
                                <div class="dropdown-content">
                                  <a routerLink="/pages/soilhealth">SOIL HEALTH</a>
                                  <a routerLink="/pages/healthyworld">HEALTHY WORLD</a>
                                  <a routerLink="/pages/growingpractices">GROWING PRACTICES</a>
                                  <a routerLink="/pages/OurCircle">OUR CIRCLE</a>
                                  <a routerLink="/pages/accolades">ACCOLADES</a>
                                </div>
                            </div>

                            <li *ngIf="data.showContactNumber==1" (click)="callPhone()" style=" cursor: pointer;"><a> Call: {{data.storePhoneNumber}}</a></li>
                            <li *ngIf="data.showBlogPage==1"><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/aboutus">About Us</a></li>
                            <li *ngIf="data.showContactPage==1"><a routerLink="/contact-us">Contact Us</a></li>
                            <li class="login" style=" cursor: pointer;"><a *ngIf="!userLoggedIn"
                                    (click)="SignIn('login')"><i class="icon-user"></i>Login</a></li>
                            <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center"
                                [color]="'white'" *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <mat-toolbar-row fxLayoutAlign="start center" style="height: auto;">

        <div fxFlex="5">

        </div>
        <div fxLayout="row"  fxLayoutGap="10px" fxFlex="15">
            <section class="padding-10">

                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </section>
        </div>

        <div fxLayout="row" fxLayoutGap="10px" fxFlex="65">

            <calsoft-Menu [color]="false"  [data]="data"  [type]="'one'" style="color: #000000;" class="menu" [categoryArray]="item"></calsoft-Menu>

        </div>

        <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
            <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
                <mat-icon style=" cursor: pointer;" fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                    (click)="showSearchBar()">search</mat-icon>
            </div>


            <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
                fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="350px">
                <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                    [searchbarborderRadius]="'15px'"></calsoft-search>
            </div>

        </div>


        <div fxLayoutAlign="end center" fxFlex="10" *ngIf="url !== '/cart' && url !== '/checkout' && url !=='/checkout/type' ">

            <button (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist'">
                <mat-icon>favorite_border</mat-icon>

            </button>

            <calsoft-cart-icon [color]="true" fxFlex></calsoft-cart-icon>

        </div>
    </mat-toolbar-row>

    <mat-toolbar fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header" fxLayout="row"
    fxLayoutAlign="space-between center" style="height: auto;z-index: 1000;">

    <mat-toolbar-row class="theme-container" fxLayoutAlign="space-between center" style="height: auto;" >

    <div fxLayout="row" fxLayoutGap="10px" fxFlex="10">
        <section>

            <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

        </section>
    </div>

    <div fxLayout="row" fxLayoutGap="10px" fxFlex="80">

        <calsoft-Menu [color]="false"  [data]="data"  [type]="'one'" style="color: #000000; z-index: 1000;" class="menu" [categoryArray]="item"></calsoft-Menu>

    </div>

    <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">
        <div *ngIf="url != '/cart'  && url !== '/checkout' && url !=='/checkout/type'">
            <mat-icon style=" cursor: pointer;"  fxLayoutAlign="center center" *ngIf="!displaySearchBar"
                (click)="showSearchBar()">search</mat-icon>
        </div>


        <div *ngIf="displaySearchBar" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
            fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="350px">
            <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                [searchbarborderRadius]="'15px'"></calsoft-search>
        </div>

    </div>

    
    <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
        <div style="color: black" fxLayout="column">
            <small>Hello, Sign in</small>
            <span>Account & Lists</span>
        </div>
    </button>

    <calsoft-HeaderUserProfileDropdown [color]="'white'" [data]="data" [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
    </calsoft-HeaderUserProfileDropdown>
    
    <div fxFlex="3">

    </div>
</mat-toolbar-row>
</mat-toolbar>
</header>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header-new>
    </div>


</div>
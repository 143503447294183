<ng-container *ngIf="data.showContactNumber==1">

    <button  (click)="call()" mat-button>
        <mat-icon style="color: white;">call</mat-icon>
     <span style="color: white;">
      {{data.storePhoneNumber}}
     </span> 
    </button>
</ng-container>


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'calsoft-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnInit {
  index: any;
  countryCode: Subject<any> = this.loaderService.countryCode;

  constructor(
    private currencyConversionService: CurrencyConversionService,
    private loaderService: LoaderService,
    private router: Router,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.index = this.currencyConversionService.getCountryCodeIndex();
    if (this.index == null) {
      let i = 0;
      this.flag = this.flags[i];
    }
    else {
      this.flag = this.flags[this.index];
    }
  }


  public flags: any = [
    { name: 'INR', image: 'assets/images/flags/inr.png' },
    { name: 'AED', image: 'assets/images/flags/aed.svg' },
    { name: 'BHD', image: 'assets/images/flags/bhd.svg' },
    { name: 'KWD', image: 'assets/images/flags/kwd.svg' },
    { name: 'OMR', image: 'assets/images/flags/omr.svg' },
    { name: 'SAR', image: 'assets/images/flags/sar.svg' },
    { name: 'USD', image: 'assets/images/flags/gb.svg' }
  ]
  public flag: any;

  public changeLang(flag, i) {
    this.flag = flag;
    this.currencyConversionService.setCurrencyCode("INR", flag.name, i);

    this.countryCode.observers = [];
    this.countryCode.observers.push(flag)

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';


    if (this.router.url.includes("/pr?sid=")) {

      let value = this.router.url.split("/pr?sid=")

      this.commonService.goToCategoryWithEncryptedKey(value[0].slice(0), value[1]);
    } else if (this.router.url.includes("?sid=")) {

      let value = this.router.url.split("?sid=")


      this.commonService.productSearch(value[0].slice(8))



    }
    else {
      this.router.navigate(['/']);
    }




  }

}

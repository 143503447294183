
    
  <div *ngIf="data.mobileType === 'android'" class="app primary" class="android-prompt">
    <div fxLayoutAlign="center center">
      <p> <b>Get Our app and shop on the go</b></p>
    </div>
   
    <button mat-button class="primary" style="color: white;" (click)="installPwa()">
      Get app
    </button>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
 

  
  <div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
    <button class="close-ios-inv" mat-button color="primary" (click)="close()"><mat-icon>close</mat-icon></button>
    <div>
      <p>To install this web app on your device tap the Menu button and then 'Add to Home screen' button</p>
    </div>
   
   <button (click)="installPwa()">
      <img src="./assets/icons/ios-menu-btn.png">
  </button>
  </div>
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CartService } from 'src/app/services/cart.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
declare var $: any;


@Component({
  selector: 'calsoft-header23',
  templateUrl: './header23.component.html',
  styleUrls: ['./header23.component.scss']
})
export class Header23Component {

  @Input() containerClass = "container";
	public serverPath = environment.commonImageApi + "logo/";
	@Input() data: any;
	toggleActive: boolean = false;
	cartProducts: any;
	popupResponse: any;
	wishlistProducts: any;
	userLoggedIn: boolean;
	page: string;
	page1: any;
	cartTotalItems: any;
	public form: UntypedFormGroup;
	url: string;
	countryCode: Subject<any> = this.loaderService.countryCode;
	index: any;
	@Input() item: any;
	@Input() languageSelection: any;

    wishCount = 0;
	displaySearchBar: boolean = false;
	display: boolean;
	mobileHeaderType:any=0;


		constructor(public activeRoute: ActivatedRoute,
      public calsoftService: CalsoftService,
      private router: Router,
      private basicAuthenticationService: UserService,
      private dialog: MatDialog,
      private userService: UserService,
      public cartServices: CartService,
      private loaderService: LoaderService,
      private location: Location) {
    }

ngOnInit() {
		this.userLogged();
		this.url = this.router.url;

		if (window.innerWidth < 1281) {
			this.display = false;
		  }else{
			this.display=true
		  }

		  this.mobileHeaderType=this.data['mobileHeaderType'];
	
	  }
	showLoginModal(event: Event): void {
		// event.preventDefault();
		// this.modalService.showLoginModal();
	}

	callPhone() {
		let phoneNumber = "tel:" + this.data.storePhoneNumber;
		window.location.href = phoneNumber;
	  }

  userLogged() {

		this.router.routeReuseStrategy.shouldReuseRoute = function () {
		  return false;
		};
	
		let basicAuthHeaderString = this.basicAuthenticationService.getAuthenticatedToken();
		let username = this.basicAuthenticationService.getAuthenticatedUser();
	
		if (basicAuthHeaderString && username) {
	
		  this.userLoggedIn = true;
		  this.cartServices.getCount();
	
		}
		else {
	
		  this.userLoggedIn = false;
		}
	  }

	  loginModel() {
		if (window.innerWidth > 768) {
	
		  let dialogRef = this.dialog.open(CommonSignInComponent, {
			width: '790px',
			data: { page: this.page1, }
		  });
	
		  dialogRef.disableClose = true;
		  dialogRef.afterClosed().subscribe(result => {
			this.ngOnInit();
	
	
		  });
	
		}
		else {
		  let dialogRef = this.dialog.open(CommonSignInComponent, {
			minWidth: '100vw', height: '100vh',
			data: { page: this.page1, }
		  });
	
		  dialogRef.disableClose = true;
		  dialogRef.afterClosed().subscribe(result => {
			this.ngOnInit();
	
	
		  });
		}
	
	  }
	
	  back() {
		this.location.back();
	  }
	
	
	  cart() {
		let basicAuthHeaderString = this.userService.getAuthenticatedToken();
		let username = this.userService.getAuthenticatedUser();
	
		if (basicAuthHeaderString && username) {
		  this.router.navigate(['/cart'])
	
		}
		else {
		  this.page1 = "cart";
		  this.loginModel();
	
		  // this.router.navigate(['/cart'])
	
		}
	
	  }
	
	  wishlist() {
		if (this.userLoggedIn) {
			this.userLoggedIn = true
			this.router.navigate(['/account/wishlist']);  
		  }
		  else {
			this.userLoggedIn = false
			this.loginModel();
			
		  }
		
	  }
	
	  SignIn() {
		// this.router.navigate(['/sign-in']);
		this.loginModel();
	  }
	
	  home() {
		this.router.navigate(['/'])
	  }
	
	
	  search() {
		this.router.navigate(['/search'])
	  }
	
	  public toggleSearch() {
		$('app-main').toggleClass('form-open');
	  }
	
	  public toggleSidebar() {
		this.calsoftService.sidenavOpen = !this.calsoftService.sidenavOpen;
	  }
	
	
	  public selectedCurrency(value) {
		this.calsoftService.currency = value;
	  }
	
	  public selectedLanguage(value) {
		this.calsoftService.language = value;
	  }
	
	  hideSearch() {
		this.displaySearchBar = false;
	  }

	  showSearchBar(){
		this.displaySearchBar = true;
	  }
	  

}


<footer>

  <div class="footer-v1 theme-container ">
    <div>
      <calsoft-subscriber></calsoft-subscriber>
    </div>
    <mat-divider></mat-divider>
    <div class="container">

      <div class="footer-top-v1">



        <div fxLayout="row" fxLayout.lt-md="column">
          <span fxFlex="1">

          </span>
          <span fxFlex>
            <span class="fotMenus font-20px"></span>
            <p style="margin-bottom: 0px" [innerHTML]="aboutData">
          </span>

          <span fxFlex="30">

            <ng-container fxLayoutAlign="center center" *ngFor="let menu of menuData">
              <mat-list class="list-sm">
                <mat-list-item>
                  <a class="fotMenus">{{siteName}}</a>
                </mat-list-item>
                <mat-list-item>
                  <a routerLink="/aboutus">About Us</a>
                </mat-list-item>
                <mat-list-item>
                  <a routerLink="/privacy">Privacy Policy</a>
                </mat-list-item>
                <mat-list-item>
                  <a routerLink="/shippingPolicy">Shipping Policy</a>
                </mat-list-item>

                <mat-list-item>
                  <a routerLink="/returnPolicy">{{data.refundPolicyName}}</a>
                </mat-list-item>

                <mat-list-item>
                  <a routerLink="/terms&conditions">Terms & Conditions</a>
                </mat-list-item>
                <mat-list-item *ngIf="data.disableCoupoun==1">
                  <p *ngIf="data.disableCoupoun==1" class="mt-1"><a routerLink="/coupon">Coupon</a></p>
                </mat-list-item>
                <mat-list-item>
                  <p *ngIf="data.showContactPage==1"><a routerLink="/contact-us">Contact Us</a> </p>
                </mat-list-item>
                <mat-list-item>
                  <p *ngIf="data.showBlogPage==1"> <a routerLink="/blog">Blog</a></p>
                </mat-list-item>
                <mat-list-item>
                  <p><a routerLink="/paymentPolicy">Payment Policy</a></p>
                </mat-list-item>
                <mat-list-item>
                  <p *ngIf="data.showBrochurePage==1"><a routerLink="/brochure">Brochure</a></p>
                </mat-list-item>

              </mat-list>
            </ng-container>

          </span>

        </div>

        <div fxLayout="row" fxLayout.lt-md="column">

          <span fxFlex>
          </span>

          <span fxFlex="30" *ngIf="showFooterIcon==1" class="col-gap" fxLayoutGap="15px" style="margin-right: 0.3rem;"
            ng>

            <div fxFlex fxLayoutAlign="start center">
              <div style="padding-left: 10px;">
                <span fxFlex *ngFor="let item of footerIconData; let i = index">

                  <button (click)="navigate(item)" mat-icon-button>

                    <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                  </button>
                </span>

              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="data.vendorEnabled == 1" class="padding-30" fxLayoutAlign="start center">
      <a class="border padding-10" (click)="Supplierlogin()" href="javascript:void(0)">Become a Supplier</a>
    </div>

    <hr>
    <div class="footer-bottom-v1" style="margin: 1px 0px;padding-bottom: 10px; ">
      <div fxLayout="row" fxLayout.lt-md="column" class="col-gap" fxLayoutAlign="space-between center">
        <div fxFlex="1">

        </div>
        <div fxFlex>
          <img src="assets/images/card.jpeg" width="218" alt="Credit Cards">
        </div>

        <div fxFlex *ngIf="data.dspeedupNavigationEnable == 1">
          <a class="underline-on-hover" target="_blank" href="https://dspeedup.com/">Powered by dSPEEDUp</a>
        </div>

        <div fxFlex>
          <small>Copyright © {{siteName}} {{currentYear}}. All rights reserved.</small>
        </div>





      </div>
    </div>
  </div>

  <div *ngIf="footerImageEnable==1">
    <div *ngIf="display">
      <div style="margin-top:auto;" fxHide.lt-md>
        <img [src]="serverPath+footerLogoSrc" onerror="this.src='';" style="width: 100%;" />
      </div>
    </div>
  </div>

</footer>

<!--
  <footer class="footer sec-padding" style="background: url(assets/images/background/footer-01.jpg);">
  
      <div style="padding-top: 20px;" class="container">
        <div class="footer-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between start">
  
  
          <div fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.xs="mt-2"
            class="footer-widget links-widget links-widget-pac">
            <div class="title">
              <h5>Address</h5>
            </div>
            <div class="row">
              <p> Ph: +91- 44-3910 3600, 3616-20, 3644-45</p>
              <p>Email :investor@calsoftgroup.com</p>
              <p> California Software Co Ltd</p>
              <p>2nd Floor, Unit 9, STPI Building,</p>
              <p> 5 Rajiv Gandhi Road,</p>
              <p>Taramani, Chennai 600113</p>
            </div>
          </div>
  
          <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="50" class="footer-widget about-widget"><a class="logo"
              href="index.html"><img style="max-width: 16%;" src="assets/images/logo/logo.png" alt="Awesome Image"></a>
  
            <div class="title">
            </div>
  
            <ul fxLayout="row" fxLayoutAlign="center center" class="social list-inline">
              <li><a href="#"><i class="fa fa-facebook"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter"></i></a></li>
              <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
            </ul>
          </div>
  
  
  
          <div fxFlex="100" fxFlex.gt-sm="23" fxFlex.sm="50" ngClass.sm="mt-2" ngClass.xs="mt-2"
            class="footer-widget subscribe-widget">
            <div class="title">
              <h5>Contact Us</h5>
            </div>
            <div class="link"><a href="#">info@calsoftgroup.com</a> </div>
            <p>Ph: +91- 44-3910 3600, 3616-20, 3644-45</p>
  
  
          </div>
  
  
  
  
  
  
        </div>
      </div>
    </footer> -->
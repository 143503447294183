import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() type: string;
  @Input() component: string="dark";
  public serverPath = environment.commonImageApi + "logo/";
  @Input() sitename: string;
  layout: any;
  constructor( private calsoftService: CalsoftService,
    private route:Router
    ) { }
  ngOnInit() {

    

  }

  closeMenu(){

    if(this.component=="menu"){
      this.calsoftService.sidenavOpen = false;
    }else{

      if(this.route.url!='/home'){
        this.route.navigate(['/']);
      }

    }

  }

}

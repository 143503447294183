import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
export const COUNTRYCODE = 'countryCode'
export const COUNTRYCODEINDEX = 'countryCodeIndex'
export const CONVERSIONVALUE = 'conversionValue'

@Injectable({
  providedIn: 'root'
})

export class CurrencyConversionService {
  index: any;
  url = environment.serverAPI + '/api/catalog/currency';
  currencyRate: any;
  constructor(
    private http: HttpClient
  ) { }
 
  getCountryCode() {
    return sessionStorage.getItem(COUNTRYCODE);
  }

  setCurrencyCode(currencyFrom, currencyTo, index) {
    sessionStorage.setItem(COUNTRYCODE, currencyTo);
    sessionStorage.setItem(COUNTRYCODEINDEX, index);

    this.getOrderById(currencyFrom, currencyTo)
  }

  getCountryCodeIndex() {
    return sessionStorage.getItem(COUNTRYCODEINDEX);
  }



  getCurrencyValue() {
    return sessionStorage.getItem(CONVERSIONVALUE);
  }

  getCurrencyRates(currencyFrom, currencyTo): Observable<Object> {
    return this.http.get(`${this.url}?currencyFrom=${currencyFrom}&currencyTo=${currencyTo}`);
  }

  getOrderById(currencyFrom, currencyTo) {

    this.getCurrencyRates(currencyFrom, currencyTo).subscribe(
      res => {
        this.currencyRate = res;
        sessionStorage.setItem(CONVERSIONVALUE, this.currencyRate);
      },
      error => {
      }
    )
  }



}


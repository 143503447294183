<div class="primary">
    <mat-toolbar class="primary" fxLayout="row" 
    fxLayoutAlign="space-between center">
      <span class="clrbg ">Supplier Registration</span>
      <button class="clrbg" matDialogClose="yes" mat-icon-button>
        <mat-icon >close</mat-icon>
      </button>
    </mat-toolbar>
</div>

<form [formGroup]="firstFormGroup">
    <div class="padding-10">

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
            <mat-form-field fxFlex appearance="outline" fxLayoutGap="10px">
                <mat-label>Enter GSTIN</mat-label>
                <input matInput formControlName="gstNo" required>
                <mat-error *ngIf="firstFormGroup.controls.gstNo.errors?.required">
                    Please fill out this field.
                </mat-error>
                <mat-error *ngIf="firstFormGroup.controls.gstNo.errors?.pattern">
                    Enter a valid format.
                </mat-error>
            </mat-form-field>
        </div>

      
            <div class="index-head padding-10" fxLayoutGap="10px">
              <span>Supplier Details</span>
            </div>
            



        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
            <mat-form-field fxFlex appearance="outline">
                <mat-label>Address</mat-label>
                <input matInput formControlName="address" required>
                <mat-error *ngIf="firstFormGroup.controls.address.errors?.required">
                  Please fill out this field.
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex appearance="outline">
                <mat-label>City</mat-label>
                <input matInput formControlName="city" required>
                <mat-error *ngIf="firstFormGroup.controls.city.errors?.required">
                  Please fill out this field.
                </mat-error>
              </mat-form-field>


              <mat-form-field fxFlex appearance="outline">
                <mat-label>Country</mat-label>

                <mat-select [disableOptionCentering]="true" #singleSelect
                    formControlName="country" autocomplete="off">
                    <mat-option>
                        <ngx-mat-select-search name="Country Search"
                            ngDefaultControl
                            [formControl]="countryFilterControl"
                            placeholderLabel="Search"
                            [preventHomeEndKeyPropagation]="true"
                            noEntriesFoundLabel="No data found">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let opt of filteredcountryTypeList"
                        [value]="opt.defaultName">
                        {{opt.defaultName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="firstFormGroup.controls.country.errors?.required">
                  Please fill out this field.
                </mat-error>

            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">

            <mat-form-field fxFlex appearance="outline">
                <mat-label>State</mat-label>
                <input matInput formControlName="state" required>
                <mat-error *ngIf="firstFormGroup.controls.state.errors?.required">
                  Please fill out this field.
                </mat-error>
              </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
                <mat-label>Pincode</mat-label>
                <input matInput formControlName="pincode" required>
                <mat-error *ngIf="firstFormGroup.controls.pincode.errors?.required">
                  Please fill out this field.
                </mat-error>
              </mat-form-field>

        </div>

        <div class="index-head padding-10" fxLayoutGap="10px">
            <span>Supplier Account Details</span>
          </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
            <mat-form-field fxFlex appearance="outline">
                <mat-label>Account Number</mat-label>
                <input matInput formControlName="accountNo" required>
                <mat-error *ngIf="firstFormGroup.controls.accountNo.errors?.required">
                    Please fill out this field.
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
                <mat-label>Confirm Account Number</mat-label>
                <input matInput formControlName="confirmAccount" required>
                <mat-error *ngIf="firstFormGroup.controls.confirmAccount.errors?.required">
                  Please fill out this field.
                </mat-error>
                <mat-error *ngIf="firstFormGroup.controls.confirmAccount.errors?.mismatch">
                  Account numbers do not match.
                </mat-error>
              </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
                <mat-label>IFSC Code</mat-label>
                <input matInput formControlName="ifscCode" required>
                <mat-error *ngIf="firstFormGroup.controls.ifscCode.errors?.required">
                    Please fill out this field.
                </mat-error>
            </mat-form-field>

        </div>

        <div class="index-head padding-10" fxLayoutGap="10px">
            <span>Supplier Store Details</span>
          </div>


        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
            <mat-form-field fxFlex appearance="outline">
                <mat-label>Store Name</mat-label>
                <input matInput formControlName="storeName" required>
                <mat-error *ngIf="firstFormGroup.controls.storeName.errors?.required">
                    Please fill out this field.
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input numbersOnly matInput formControlName="phoneNo" minlength="10"
                maxlength="10" required>
                <mat-error *ngIf="firstFormGroup.controls.phoneNo.errors?.required">
                    Please fill out this field.
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
                <mat-label>Your Full Name</mat-label>
                <input matInput formControlName="name" required>
                <mat-error *ngIf="firstFormGroup.controls.name.errors?.required">
                    Please fill out this field.
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
            <mat-form-field fxFlex appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" required>
                <mat-error *ngIf="firstFormGroup.controls.email.errors?.required">
                    Please fill out this field.
                </mat-error>
                <mat-error *ngIf="firstFormGroup.controls.email.errors?.email">
                    Please enter a valid email address.
                </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" required>
                <mat-error *ngIf="firstFormGroup.controls.password.errors?.required">
                    Please fill out this field.
                </mat-error>
            </mat-form-field>
            </div>

    </div>
    <div class="padding-10" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
    <button mat-raised-button mat-dialog-close tabindex="-1">Cancel</button>
    <button [disabled]="textAccount !='OK'" (click)="createSupplier()" mat-raised-button>
        {{textAccount}}
        <mat-icon *ngIf="textAccount !='OK' ">
            <mat-spinner color="primary" diameter="20">
            </mat-spinner>
        </mat-icon></button>
</div>
</form>



import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss']
})
export class CallComponent implements OnInit {

  constructor() { }

  @Input() data: any;


  ngOnInit(): void {
  }

  call(){
    let phonenumber="tel:+"+this.data.storePhoneNumber;
   // console.log(phonenumber);
    
    window.location.href=phonenumber
   }


}

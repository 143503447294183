import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewsletterPopupService {

  private popupVisible = new BehaviorSubject<boolean>(false);

  getPopupVisibility() {
    return this.popupVisible.asObservable();
  }

  showPopupWithDelay(delay: number = 2000) {
    timer(delay).pipe(take(1)).subscribe(() => {
      this.showPopup();
    });
  }

  private showPopup() {
    this.popupVisible.next(true);
  }

  hidePopup() {
    this.popupVisible.next(false);
  }
}
<div *ngIf="display">
<mat-toolbar style="border-bottom: 1px solid #cbc0c0;  height: auto;"   
fxHide  fxShow.gt-md  class=" app-toolbar bg" fxLayoutAlign="space-between center">
<mat-toolbar-row class="theme-container" style="height: 100%;" fxLayoutAlign="space-between center" >

       <div fxLayout="row" fxLayoutGap="10px" fxFlex="20" >


           <section>
             
               <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
              
           </section>
       </div>

    <div  class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row"
       fxLayoutAlign="start center" fxFlex fxFlex.gt-sm="350px">
       <calsoft-search [searchbarborderRadius]="'15px'"></calsoft-search>
   </div>

      

       <!-- <div fxFlex fxHide fxShow.gt-sm class="list-flex user-tool"> -->
           <div  fxLayout="row"  fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutGap="10px" fxFlex="100">

                <calsoft-Menu class="padding-10"  style="z-index: 1000;" fxLayoutAlign="center center" [data]="data" [type]="'one'" [categoryArray]="item"></calsoft-Menu>
    
            </div>
           
            <div fxLayoutAlign="end center" >

           
           <div *ngIf="!userLoggedIn"(click)="SignIn('login')" fxLayoutAlign="end center">
               <button mat-flat-button  style="color: black" *ngIf="!userLoggedIn">LOGIN/SIGNUP
               </button>
           </div>

           <calsoft-HeaderUserProfileDropdown [color]="'black'" [data]="data"  fxLayoutAlign="end center"  [color]="'white'" *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>

           <div fxLayoutAlign="end center"  *ngIf="url != '/cart'">
               <calsoft-cart-icon  [color]="true"></calsoft-cart-icon>
           </div>
        </div>
           
       </div>
     </mat-toolbar-row>

</mat-toolbar>
<mat-toolbar fxHide fxShow.gt-md >

</mat-toolbar>
</div>

<div *ngIf="!display">
    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header-new>
    </div>
    
</div>
<!-- Mobile View  end -->


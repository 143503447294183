<div fxLayout="row" class="pointer" fxLayoutAlign="start center" fxLayoutGap="5px" mat-button
    [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
    <img [src]="flag.image" width="18">
    <span fxShow="false" fxShow.gt-sm class="flag-menu-title icon_color font16">{{flag.name}}</span>
    <mat-icon class="mat-icon-sm caret icon_color">arrow_drop_down</mat-icon>
</div>
<mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
    <span (mouseleave)="langMenuTrigger.closeMenu()">
        <button fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" mat-menu-item
            *ngFor="let flag of flags let i=index;" (click)="changeLang(flag,i)">
            <img [src]="flag.image" width="18"> {{flag.name}}
        </button>
    </span>
</mat-menu>
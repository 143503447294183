<div *ngIf="display">
<mat-toolbar fxHide fxShow.gt-md  class="header-v2 primary" fxLayoutAlign="space-between center">
    <mat-toolbar-row style="height: 100%;" class="theme-container"  fxLayoutAlign="space-between center">
  
                <div fxLayout="row" fxLayoutGap="10px" >
  
  
                    <section>
                        <calsoft-logo [sitename]="data.siteName" [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
  
                    </section>
                    <div class="search-bar" fxLayoutGap="10px" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="center center"
                        fxFlex fxFlex.gt-sm="400px">
  
                        <calsoft-search fxLayoutAlign="center center"></calsoft-search>
                    </div>
                </div>
  
                <div fxHide fxShow.gt-sm class="list-flex user-tool">
                  <calsoft-call [data]="data"></calsoft-call>
                    <calsoft-language *ngIf="languageSelection==1" style="color: #ffffff"></calsoft-language>
  
                    <div *ngIf="url!='/cart' && url!='/checkout'&& url!='/checkout/type' "  class="_sadh"
                        fxLayoutAlign="start center" fxHide fxShow.gt-sm fxShow.gt-sm>
                
                        <calsoft-cart-icon [color]="false" ></calsoft-cart-icon>
                    </div>
  
  
                    <div *ngIf="!userLoggedIn"(click)="SignIn('login')" fxLayoutAlign="start center">
                        <button mat-flat-button class="primary" style="color: #ffffff" *ngIf="!userLoggedIn">LOGIN/SIGNUP
                        </button>
                    </div>
  
                    <calsoft-HeaderUserProfileDropdown [data]="data"  *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                </div>
              </mat-toolbar-row>
  
  </mat-toolbar>
  <mat-toolbar fxHide  fxShow.gt-md >
  
  
 
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center"
        style=" background: #fff;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);padding: 0px;color: #000000;height: -webkit-fill-available"
        class="header-v2-bottom main-menu-wrap theme-container">
  
        <calsoft-Menu  [data]="data" [type]="'one'" style="z-index: 1000;" [categoryArray]="item"></calsoft-Menu>
  
  </mat-toolbar-row >
    </mat-toolbar>


  <mat-toolbar style="z-index: 1000;" fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header primary "  fxLayout="row" >
    <mat-toolbar-row style="height: 100%;" class="theme-container" fxLayoutAlign="space-between center" >
    <section>
        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

    </section>
    <section>
        <div class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center" fxFlex
            fxFlex.gt-sm="500px">
            <calsoft-search></calsoft-search>
        </div>
    </section>
    <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
        <calsoft-language [type]="scroll" *ngIf="languageSelection==1" style="color: #ffffff"></calsoft-language>

        <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
            <div style="color: #ffffff" fxLayout="column">
                <small>Hello, Sign in</small>
                <span>Account & Lists</span>
            </div>
        </button>
        <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
        </calsoft-HeaderUserProfileDropdown>
        <calsoft-cart-icon [color]="false"></calsoft-cart-icon>
    </section>
</mat-toolbar-row>
</mat-toolbar>

</div>
<div *ngIf="!display">
   
    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header-new>
    </div>
    
</div>

  
  
  
  <!-- <calsoft-mobile-header-fixed [url]="url" [userLoggedIn]="userLoggedIn">
  
  </calsoft-mobile-header-fixed> -->
  <!-- Mobile View  end -->
  
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GaEventCategoryDirective } from './ga-event-category.directive';



@NgModule({
  declarations: [
    GaEventCategoryDirective
  ],
  imports: [
    CommonModule
  ],exports:[
    GaEventCategoryDirective
  ]
})
export class GoogleAnalyticsDirectiveModule { }


<ng-container *ngIf="searchDisabled">


    <div  class="search-wrapper text-muted" fxLayout="row"
        [ngClass]="{ 'focus': focused, 'has-input': input }" fxFlex (vrClickOutside)="closeDropdown()">
        <mat-icon *ngIf="search=='' || search==null" class="search-icon" style=" cursor: pointer;" (click)="hideSearchBar()">search</mat-icon>
        <input type="search" [ngStyle]="{'border-radius':searchbarborderRadius }"   autocomplete="off" spellcheck="false" class="search-input text-muted" [(ngModel)]="search"
            (input)="filterOption($event)" (keyup.enter)="onKeydown($event)" (focus)="openDropdown()"
            placeholder="Search..." #inputElem [matAutocomplete]="auto">
        <mat-autocomplete  class="mat-elevation-z2 autocmp" #auto="matAutocomplete">
            <mat-option  *ngFor="let filter of filteredOptions" (keydown.enter)="getProducts(filter.name)"
                (click)="getProducts(filter.name)" [value]="filter.name">
                <mat-icon>search</mat-icon>
    
                <span>{{ filter.name }}</span>
            </mat-option> 
            <!-- <mat-option *ngIf="empty">
                <mat-icon>search</mat-icon>
                <span>No Products Found</span>
            </mat-option> -->
    
        </mat-autocomplete>
    </div>
    </ng-container>
    
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { Constant } from '../constants/constant';
import { EcryptServiceService } from './ecrypt-service.service';
import { catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ConfigServiceService {

  url = environment.serverAPI + '/api/catalog/siteData';

  url1 = environment.serverAPI4 + '/api/medical/delivery/site/data';

  url2 = environment.serverAPI4 + '/api/ig/medical/delivery/site/data';

  url6 = environment.serverAPI4 + '/' + 'api/product';

  url7 = environment.serverAPI4 + '/' + 'api/ig/product';
  constructor(private http: HttpClient,
	private ecryptService: EcryptServiceService,
	private userService: UserService) { }
	public responseCacheConfig = new Map();
  // getConfigData(): Observable<Object> {
	// 	return this.http.get(`${this.url}`);
	// }

  public getConfigData(): Observable<any> {

	   

	const configFromCache = this.responseCacheConfig.get(`${this.url}`);

	if (configFromCache) {
	  return of(configFromCache); // Return cached data as an Observable
	}
	
	let finalData = localStorage.getItem("m-d");
	if (finalData) {
	  let decryptedData = JSON.parse(this.ecryptService.decryptionAES(finalData));
	  if (decryptedData !== null) {
		this.responseCacheConfig.set(`${this.url}`, decryptedData); // Cache the data
		return of(decryptedData); // Return decrypted data as an Observable
	  }
	}
	
	// If the data is not in cache or localStorage, perform the HTTP request
	return this.http.get<any>(`${this.url}`).pipe(
	  catchError(error => {
		console.error('Error fetching configuration:', error);
		return of(null); // Return an empty value or handle the error as required
	  }),
	  tap(config => this.responseCacheConfig.set(`${this.url}`, config)) // Cache the retrieved data
	);

		
	}

	public medicalDeliveryOption(): Observable<any> {
		let authourized = this.userService.getAuthenticatedUser();
		let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
			if (authourized==null||guestLoginEnabled=="1") {
				return this.http.get(`${this.url2}`);
	
			}else{
				return this.http.get(`${this.url1}`);
			}
	}

	deleteImage(data: any): Observable<Object> {
		let authourized = this.userService.getAuthenticatedUser();
		let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
			if (authourized==null||guestLoginEnabled=="1") {
				return this.http.post(`${this.url7}/deleteImage`, data);
			}else{
				return this.http.post(`${this.url6}/deleteImage`, data);
			}
	
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupplierRegistrationService {

  url = environment.serverAPI4+ "/api/ig/supplierRegister";

  url1 = environment.serverAPI4 + "/api/supplierRegister"; 

  constructor(private http: HttpClient) {
  }


  submitSupplier(supplier:any): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url}`, supplier);
  }


}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalsoftService } from 'src/app/services/Calsoft.service';

@Component({
  selector: 'calsoft-menu-iteration2',
  templateUrl: './menu-iteration2.component.html',
  styleUrls: ['./menu-iteration2.component.scss']
})
export class MenuIteration2Component {
  openMenu: boolean=false;

  constructor(public router: Router,
    public calsoftService: CalsoftService,
    ) { }

  ngOnInit(): void {
  }

  open() {

  if(!this.openMenu){
    this.openMenu=true;
  }else{
    this.openMenu=false;
  }
  

  }
  close(){
    this.openMenu=false;
  }

  appPages2 = [
    {
       title: 'SOILHEALTH',
       url: '/soilhealth',
       icon: 'add_shopping_cart'
    },
    {
       title: 'HEALTHY WORLD',
       url: '/HEALTHYWORLD',
       icon: 'text_snippet'
    },
    {
       title: 'GROWING PRACTICES',
       url: '/growingpractices',
       icon: 'favorite'
    },
    {
       title: 'OUR CIRCLE',
       url: '/OurCircle',
       icon: 'person'
    },
    {
       title: 'ACCOLADES',
       url: '/ACCOLADES',
       icon: 'payments'
    },
  ]


  goToUrl(url){
    this.router.navigate([url]);
    this.calsoftService.sidenavOpen = false;
  }
}
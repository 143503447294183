import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { Constant } from 'src/app/constants/constant';
import { PincodeService } from 'src/app/services/pincode.service';

@Component({
  selector: 'app-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss']
})
export class PincodeComponent implements OnInit {

  checkout: any;

  @Input() type: string;
  @Output() onConfirmAddress: EventEmitter<any> = new EventEmitter<any>();
  @Output() getCustomerData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('availablePincode') availablePincode: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public page: any = 0;
  public size: any = 5;

  sortParam: any = 'id' + "," + Constant.Asc;

  visible: boolean;
  index: any;
  operation: string;
  defalutValue: any;
  loginChange: boolean;
  pincodeList: any;
  filteredcountryTypeList: any;
  countryTypeList: any;
  countryControl: UntypedFormControl = new UntypedFormControl();
  countryFilterControl: UntypedFormControl = new UntypedFormControl();
  protected _onDestroy = new Subject<void>();
  options: any;
  countryList: any;
  exists: boolean;
  pinCodeList: any;
  elements: any;
  pageList: any;
  pageSizeValue: any;
  search: any;
  indexPostalCode: any = 0;
  pincodeError: boolean=false;
  @ViewChild('filter', { static: true }) filter: ElementRef;
  pinCodeSelection: any;

  searchTermMain: any;

  searchTermMainPresent: any = true;
  country: any;
  guestLoginEnabled: string;
  authourized: any;
  displayedColumns: string[] = ['pinCode'];
  constructor(
    private pinCodeService: PincodeService,
    public snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAvailabePincode();
  }

  searchValue = new UntypedFormControl('');
  searchFilterMain() {

    if (this.searchValue.value.length==0) {
      this.getAvailabePincode();
    } else {
      this.page = 0;
      this.size = 5;
      this.search = this.searchValue.value;


      let searchTerm = "pinCode:'" + '*' + this.search + '*' + "'  AND isActive:'" + '*' +    1  + '*' + "'"

      this.pinCodeService.searchPincode(searchTerm, this.page, this.size, this.sortParam).subscribe(
        res => {
          this.elements = res['numberOfElements'];
          this.pinCodeList = res['content'];
          this.pageList = res['totalElements'];
          this.pageSizeValue = this.getPageSize(this.pageList);
        },
        () => {
        })
    }

  }
  getPageSize(size) {
    if (size >= 10) {
      if (size > 10) {
        return [5, 10, 15];
      }
      else if (size <= 10 && size > 5) {
        return [5, 10];
      }
    }
    else if (size <= 10 && size > 5) {
      return [5, 10];
    }
    else {
      return null;
    }

  }

  getAvailabePincode() {

    this.pinCodeService.getPincode(this.page, this.size, this.sortParam).subscribe(
      res => {
        this.pinCodeList = new MatTableDataSource();
        this.elements = res['numberOfElements'];
        this.pinCodeList = res['content'];
        this.pinCodeList.sort = this.sort;
        this.pinCodeList.paginator = this.paginator;
        this.pageList = res['totalElements'];
        this.pageSizeValue = this.getPageSize(this.pageList);

      },
      () => {
      }
    )
  }
  getUpdate(event) {
    if(this.size!=event.pageSize){
      this.page =0;
    }else{
        this.page = event.pageIndex;
    }
    this.paginationDetail.next(event);
  
    this.size = event.pageSize;
    this.getAvailabePincode();

  }
  paginationDetail = new BehaviorSubject({
    length: 5,
    pageIndex: 0,
    pageSize: 5
  });
  sortData(event) {
    this.page = 0;
    this.size = 5;
    this.sortParam = event.active;
    if (event.direction == 'asc') {
      this.sortParam = this.sortParam + "," + Constant.Asc;
    } else if (event.direction == 'desc') {
      this.sortParam = this.sortParam + "," + Constant.Dsc;
    }
    this.getAvailabePincode();
  }
  
}



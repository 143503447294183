import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  url = environment.serverAPI + '/api/catalog';
 
  constructor(private http: HttpClient) { }

  getFooterData(): Observable<Object> {
    return this.http.get(`${this.url}/footericon`);
  }

  public responseCacheLanguage = new Map();
  public getLanguage(): Observable<any> {
    //let value = this.getCartCount();
    let response: any = null;
      const cacheLanguage = this.responseCacheLanguage.get(
        `${this.url}/language`
      );

      if (cacheLanguage != undefined) {
        return of(cacheLanguage);
      }
     else {
      response = this.http.get<any>(`${this.url}/language`);
      response.subscribe((value) =>
        this.responseCacheLanguage.set(`${this.url}/language`, value)
      );
    }
    return response;
  }
}

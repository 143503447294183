  <div class="container">
    <div class="popup-container">

      <div fxFlex="5">
  
      </div>
      <div class="left-content" >
      
        <div fxLayout="column" fxLayoutAlign="center center"> 
  
          <div class="logo padding-10">
            <img [src]="serverPath1+'logo.png'"  onerror="this.src='assets/images/error/logoerror1.png';" alt="logo" width="80" fxFlex="100">
          </div>
         
  
          <div class="title"  fxLayout="column">
            <h2 class="banner-title">{{adsHomePageTitle}}</h2>
          </div>
    
          <div class="description">
            <p>{{adsHomePageDescription}}</p>
          </div>

          <form [formGroup]="firstFormGroup" class="subscribe-search-form container" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4"
          ngClass.sm="mt-2" ngClass.xs="mt-2">
          
          <div fxLayout="column" fxLayout.gt-sm="row" fxFlex>
            <input class="text" formControlName="subscriberEmail" placeholder="Your Email address..." fxFlex>
            <button [disabled]="firstFormGroup.invalid" [class.disabled]="firstFormGroup.invalid" mat-raised-button color="accent" (click)="subscribe()" type="button"
              class="mat-elevation-z0 text-muted">Subscribe</button>
          </div>
        
          <mat-error *ngIf="firstFormGroup.hasError('subscriberEmail')" class="mt-2">Please enter a valid email address.</mat-error>
        </form>

      <!-- <div fxLayout="row" fxLayoutAlign="center center" class="padding-10">
        <mat-checkbox id="register-policy-2" >Do not show this popup again</mat-checkbox>
      </div> -->
  
        </div>
       
      </div>
  
      <div fxFlex="5">
  
      </div>
  
      <div class="right-content">
        <img [src]="serverPath + adsHomePageImage" flex="100" [alt]="serverPath + adsHomePageImage" />
        <button fxLayoutAlign="end center" class="clrbg close padding-10" (click)="closePopup()" matDialogClose="yes" mat-icon-button>
          <mat-icon style="color: black;" >close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constant } from 'src/app/constants/constant';
import { AboutService } from 'src/app/services/about.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';

@Component({
  selector: 'calsoft-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss']
})
export class SubscriberComponent implements OnInit {
  firstFormGroup: UntypedFormGroup;
  data:any;
  @Input() subscribeText: any;

  constructor(
    private aboutService: AboutService,
    public formBuilder: UntypedFormBuilder,
    private configService: ConfigServiceService,
    public snackBar: MatSnackBar, ) { }

  ngOnInit() {
    this.firstFormGroup = this.formBuilder.group({
      'subscriberId': ['',],
      'storeId': ['',],
      'changeStatusAt': ['',],
      'customerId': ['',],
      'subscriberEmail': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      'subscriberStatus': ['',],
      'subscriberConfirmCode': ['',],
    });

    this.getConfigData();
  }


  subscribe() {
    this.firstFormGroup.value.storeId = 1;
    this.firstFormGroup.value.subscriberStatus = 1;
    this.firstFormGroup.value.operationType = "New";
    this.aboutService.subscriper(this.firstFormGroup.value).subscribe(
      response => {
        if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
          this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
          this.firstFormGroup.reset();
        } else {
          this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
        }
      },
      (err) => { });
  }

  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          this.data = data;
          if (!this.data.subscribeText) {
            this.subscribeText = 'Subscribe Our Newsletter';
          }
          this.subscribeText = data['subscribeText']

        }, error => {

        }
      );
  }

}


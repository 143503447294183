import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';
import { SearchComponent } from '../search.component';

@Component({
  selector: 'calsoft-search2',
  templateUrl: './search2.component.html',
  styleUrls: ['./search2.component.scss']
})
export class Search2Component implements OnInit {
  input: string;
  search: any;
  page: number;
  size: number;
  productName: any;
  filteredOptions: any;
  focused: boolean;
  sortParam: string;
  empty: any;
  scrollTop: number;
  @Input() scroll: any=true;
  searchDisabled: boolean=true;
  
  constructor(
    private dialog: MatDialog,
    public productService: ProductService,
    private commonService: CommonService,
  
  ) { }

  ngOnInit(): void {
  }

  
  searchPopup() {
    if (window.innerWidth > 768) {
  
      let dialogRef = this.dialog.open(Search2Component, {
        width: '790px',
        data: { page: 'address' }
      });
  
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
       
  
  
      });
  
    }
    else {
      let dialogRef = this.dialog.open(Search2Component, {
        minWidth: '100vw', height: '100vh',
        data: { page: 'address', }
      });
  
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        
      });
    }
  
  }

  openDropdown() {
    this.focused = true;
  }

  closeDropdown() {
    this.focused = false;
  }

  

  onKeydown(event) {
    let value1=event.target.value;
  value1= value1.replace(/AND/g, 'asasfas');
  value1= value1.replace(/OR/g, 'bhhasfas');
    if (event.target.value.trim().length > 1) {
      this.saveSearchKeyWords(event.target.value);
      this.commonService.productSearch(value1)
    }
    if (event.key === 'Enter') {
      if (value1.trim().length > 1) {
        this.saveSearchKeyWords(value1);
        this.commonService.productSearch(value1);
      }
      this.closeDialog();
    }
  }
  saveSearchKeyWords(i) {

    let search = {
      "queryText": i,
      "numResults": 0,
      "popularity": 0,
      "redirect": null,
      "synonymFor": null,
      "storeId": 1,
      "displayInTerms": 1,
      "isActive": 0,
      "isProcessed": 0,
      "operationType": "New"
    }



    this.commonService.saveSearchKeyWords(search).subscribe(
      (response) => {

      },
      (err) => { });

  }

    /**
    * On window scroll add class header-fixed.
    */
     @HostListener('window:scroll', ['$event'])
     onScrollEvent($event) {
     
if(this.scroll){
  if(document.documentElement.scrollTop!=0){{

    this.filteredOptions=[]
    this.search ='';
  }}
 }
}


@ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;

filterOption(event) {

  if (event.target.value.trim().length > 1) {


    this.search = event.target.value;

      let value=1;
      let value1=event.target.value;

      value1= value1.replace(/AND/g, 'asasfas');
      value1= value1.replace(/OR/g, 'bhhasfas');

      let search = "name:" + '*' + value1.trim() + '*'
      + "' OR sku:" + '*' + value1.trim() + '*' + "' "
      + "' OR keyword:" + '*' + value1.trim() + '*' + "' "
    this.page = 0;
    this.size = 10;
    this.sortParam = 'price,DESC';
   
    this.productService.getProductBySearch(search, this.page, this.size, this.sortParam).subscribe(
      res => {
    if(res!=null){
      this.filteredOptions = res['content'];
      this.empty=res['empty'];
    }else{
      this.filteredOptions = []
      this.empty=true;
    }
      
      })
  }


}

close() {
  this.dialog.closeAll();
}

closeDialog(){

}

}

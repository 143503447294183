import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'calsoft-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  input: string;

  @Input() searchbarborderRadius:any="0px";

  search: any;
  page: number;
  size: number;
  productName: any;
  filteredOptions: any;
  focused: boolean;
  sortParam: string;
  empty: any;
  scrollTop: number;
  @Input() scroll: any=true;
  searchDisabled: boolean=true;
  constructor(
    public productService: ProductService,
    private commonService: CommonService,
    private router:Router,
    
  ) { }

  ngOnInit() {

    this.scrollTop=document.documentElement.scrollTop;

    if(this.router.url=='/cart'||this.router.url=='/checkout/type'||this.router.url=='/checkout'){
      this.searchDisabled=false;
    }
   

  }


  @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;

  filterOption(event) {

    if (event.target.value.trim().length > 1) {


      this.search = event.target.value;

        let value=1;
        let value1=event.target.value;

        value1= value1.replace(/AND/g, 'asasfas');
        value1= value1.replace(/OR/g, 'bhhasfas');

        let search = "name:" + '*' + value1.trim() + '*'
        + "' OR sku:" + '*' + value1.trim() + '*' + "' "
        + "' OR keyword:" + '*' + value1.trim() + '*' + "' "
      this.page = 0;
      this.size = 10;
      this.sortParam = 'price,DESC';
     
      this.productService.getProductBySearch(search, this.page, this.size, this.sortParam).subscribe(
        res => {
      if(res!=null){
        this.filteredOptions = res['content'];
        this.empty=res['empty'];
      }else{
        this.filteredOptions = []
        this.empty=true;
      }
        
        })
    }


  }

  onKeydown(event) {
    let value1=event.target.value;
 value1= value1.replace(/AND/g, 'asasfas');
  value1= value1.replace(/OR/g, 'bhhasfas');
    if (event.target.value.trim().length > 1) {
      this.saveSearchKeyWords(event.target.value);
      this.commonService.productSearch(value1)
    }
  }
  getProducts(productName) {
    productName= productName.replace(/AND/g, 'asasfas');
    productName= productName.replace(/OR/g, 'bhhasfas');
    this.saveSearchKeyWords(productName);
    this.commonService.productSearch(productName)

  }

  openDropdown() {
    this.focused = true;
  }

  closeDropdown() {
    this.focused = false;
  }

  saveSearchKeyWords(i) {

    let search = {
      "queryText": i,
      "numResults": 0,
      "popularity": 0,
      "redirect": null,
      "synonymFor": null,
      "storeId": 1,
      "displayInTerms": 1,
      "isActive": 0,
      "isProcessed": 0,
      "operationType": "New"
    }



    this.commonService.saveSearchKeyWords(search).subscribe(
      (response) => {

      },
      (err) => { });

  }

    /**
    * On window scroll add class header-fixed.
    */
     @HostListener('window:scroll', ['$event'])
     onScrollEvent($event) {
     
if(this.scroll){
  if(document.documentElement.scrollTop!=0){{

    this.filteredOptions=[]
    this.search ='';
  }}
 }
}

@Output() callhideSearchBar: EventEmitter<any> = new EventEmitter<any>();

hideSearchBar(){ 
  this.callhideSearchBar.emit(false);
}
  
}

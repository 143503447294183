<div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center"
    class="border-bottom-mute subscribe-block theme-container">
    <div fxFlex="100" fxFlex.gt-sm="33" fxLayout="column" fxLayoutAlign="center center">
        <h1 class="fw-500 font28">{{subscribeText}}</h1>
    </div>
    <form [formGroup]="firstFormGroup" class="subscribe-search-form container" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4"
        ngClass.sm="mt-2" ngClass.xs="mt-2">
        <input style="letter-spacing: 1px;" type="text" class="text" formControlName="subscriberEmail" placeholder="Your Email address..."
            fxFlex>
        <mat-error *ngIf="firstFormGroup.hasError('subscriberEmail')">jjhv</mat-error>

        <button  [disabled]="firstFormGroup.invalid" [class.disabled]="firstFormGroup.invalid"  mat-raised-button color="accent" (click)="subscribe()" type="button"
            class="mat-elevation-z0 text-muted">Subscribe</button>
    </form>
</div>

import { UntypedFormGroup, UntypedFormControl, ValidatorFn, AbstractControl } from '@angular/forms';

export function emailValidator(control: UntypedFormControl): { [key: string]: any } {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return { invalidEmail: true };
    }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: UntypedFormGroup) => {
        let password = group.controls[passwordKey];
        let passwordConfirmation = group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({ mismatchedPasswords: true })
        }
    }
}

export function conditionalValidator(condition: (() => boolean), validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!condition()) {
            return null;
        }
        return validator(control);
    }
}

export function download(data: any) {

    //var filename=headers['filename'];
    var filename = 'order_invoice.pdf';
    var contentType = data.headers.get('content-type');
    var linkElement = document.createElement('a');
    try {
        var blob = new Blob([data.body], { type: contentType });
        var url = window.URL.createObjectURL(blob);

        linkElement.setAttribute('href', url);
        linkElement.setAttribute("download", filename);

        var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        });
        linkElement.dispatchEvent(clickEvent);
    } catch (ex) {

    }
}

export function validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        control.markAsTouched({ onlySelf: true });
    });
}


export function isNumeric(number: any) {

    const numericValue = parseFloat(number);
    
    if (typeof numericValue === 'number' && !isNaN(number)) {
        return true;
    } else {
        return false;
    }


}



export class comparisonValidator {
    /**
  * Must match validator
  *
  * @param controlPath A dot-delimited string values that define the path to the control.
  * @param matchingControlPath A dot-delimited string values that define the path to the matching control.
  */
    static mustMatch(controlPath: string, matchingControlPath: string): ValidatorFn {
        return (formGroup: UntypedFormGroup): null => {

            // Get the control and matching control
            const control = formGroup.get(controlPath);
            const matchingControl = formGroup.get(matchingControlPath);

            // Return if control or matching control doesn't exist
            if (!control || !matchingControl) {
                return;
            }

            // Delete the mustMatch error to reset the error on the matching control
            if (matchingControl.hasError('mustMatch')) {
                delete matchingControl.errors.mustMatch;
                matchingControl.updateValueAndValidity();
            }

            // Don't validate empty values on the matching control
            // Don't validate if values are matching
            if (this.isEmptyInputValue(matchingControl.value) || control.value === matchingControl.value) {
                return;
            }

            // Set the validation error on the matching control
            matchingControl.setErrors({ mustMatch: true });
        };
    }

    /**
 * Check for empty (optional fields) values
 *
 * @param value
 */
    static isEmptyInputValue(value: any): boolean {
        return value == null || value.length === 0;
    }

}

export const trimValidator: ValidatorFn = (control: UntypedFormControl) => {
    if (control.value.startsWith(' ')) {
        return {
            'trimError': { value: 'control has leading whitespace' }
        };
    }
    if (control.value.endsWith(' ')) {
        return {
            'trimError': { value: 'control has trailing whitespace' }
        };
    }
    return null;
};



export function noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { 'whitespace': true };
}


import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class ShareChatTrackingService {

  initializeTracking(sdkId: string): void {
    window['scSdkId'] = sdkId;
    window['scLayer'] = window['scLayer'] || [];
    
    window['scq'] = (eventName: string, eventType: string, p: any) => {
      const props = p || {};
      window['scLayer'].push({
        eventName: eventName,
        eventType: eventType,
        meta: props,
        eventFireTs: Date.now()
      });
    };

    window['scq']("PAGE_VIEW", "AUTO", {
      pageUrl: window.location.href
    });

    const scr = document.createElement('script');
    scr.type = 'text/javascript';
    scr.async = true;
    scr.src = 'https://sc-events-sdk.sharechat.com/web-sdk.js';
    
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(scr, x);
  }

  sendCustomEvent(eventName: string, eventType: string, props?: any): void {
    window['scq'](eventName, eventType, props);
  }
}
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[calsoftGaEventCategory]'
})
export class GaEventCategoryDirective {

  constructor() { 

   
  }

  @Input() gaCategory: string;

}

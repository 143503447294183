import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from './services/common.service';
import { Constant } from 'src/app/constants/constant';
import { PwaService } from './services/pwa.service';
import { EcryptServiceService } from './services/ecrypt-service.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver 
{

    constructor(
        private httpClient: HttpClient,
        private commonService:CommonService,
        private pwaService:PwaService,
        private ecryptService: EcryptServiceService,
    ) {
    }

    private _loadNavigation(): Observable<any> {
        return this.httpClient.get<any>(
            `${environment.serverAPI}/api/catalog/siteData`).pipe(
            map(
              data => {
 
                let value = this.ecryptService.encryptionAES(JSON.stringify(data));
                
                localStorage.setItem("m-d",value);

                localStorage.setItem(Constant['GUEST_LOGIN'],data['guestLogin'])

                localStorage.setItem(Constant['BANNER_HEIGHT'],data['bannerHeight']);

                localStorage.setItem(Constant['BANNER_WIDTH'],data['bannerWidth']);

                localStorage.setItem(Constant['BANNER_HEIGHT_MOBILE'],data['bannerMobHeight']);

                localStorage.setItem(Constant['BANNER_WIDTH_MOBILE'],data['bannerMobWidth']);

                
                localStorage.setItem(Constant['BANNER_HEIGHT_TABLET'],data['bannerTabletHeight']);

                localStorage.setItem(Constant['BANNER_WIDTH_TABLET'],data['bannerTabletWeight']);

                localStorage.setItem(Constant['GOOGLE_ANALYTICS_ENABLED'],data['googleAnalyticsId']);
                
                localStorage.setItem('shareChat',data['shareChat']);

                localStorage.setItem(Constant['GOOGLE_ANALYTICS_ENABLED'],data['googleAnalyticsId']);
                localStorage.setItem('pwa-Enabled',data['spinnerEnabled']);

            
                if(data.spinnerEnabled==1){
                    this.pwaService.initPwaPrompt();

                }
             
                this.commonService.setCurrencyValue(data['currencyBase'])
                  return data;


              }
            )
          );
    }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
       
        return forkJoin([


            this._loadNavigation(),
          

        ]).pipe(
            map((data) => {
                return {
                    layout: data[0]['layout'],
                    theme: data[0]['color'],
                    languageSelection:data[0]['languageSelection'],
                    totalValue:data[0]
                };
            })
        );
    }
    
}

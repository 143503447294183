import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'texttransform'})
export class TextTransformPipe implements PipeTransform {
  transform(value: string): string {
    const splitBy = '2330'
  
    let  splittedText:any =  value.replace(/([*+?^=!:${}()|\[\]\/\\])/g,'');
     splittedText = splittedText.replace('xMl3Jk','/\%/g');

    return splittedText;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalsoftService } from 'src/app/services/Calsoft.service';

@Component({
  selector: 'calsoft-menu-iteration',
  templateUrl: './menu-iteration.component.html',
  styleUrls: ['./menu-iteration.component.scss']
})
export class MenuIterationComponent implements OnInit {
  openMenu: boolean=false;

  constructor(public router: Router,
    public calsoftService: CalsoftService,
    ) { }

  ngOnInit(): void {
  }

  open() {

  if(!this.openMenu){
    this.openMenu=true;
  }else{
    this.openMenu=false;
  }
  

  }
  close(){
    this.openMenu=false;
  }

  appPages2 = [
    {
       title: 'Privacy Policy',
       url: '/privacy',
       icon: 'add_shopping_cart'
    },
    {
       title: 'Shipping Policy',
       url: '/shippingPolicy',
       icon: 'text_snippet'
    },
    {
       title: 'Refund | Cancellation Policy',
       url: '/returnPolicy',
       icon: 'favorite'
    },
    {
       title: 'Terms & Conditions',
       url: '/terms&conditions',
       icon: 'person'
    },
    {
       title: 'Payment-Policy',
       url: '/paymentPolicy',
       icon: 'payments'
    },
  ]


  goToUrl(url){
    this.router.navigate([url]);
    this.calsoftService.sidenavOpen = false;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'calsoft-left-menu-sub',
  templateUrl: './left-menu-sub.component.html',
  styleUrls: ['./left-menu-sub.component.scss']
})
export class LeftMenuSubComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  @Input() category: any;

  ngOnInit(): void {
  }

  goToCategory(menu) {
    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);
  }

}

<div *ngIf="display">
  <mat-toolbar style="position: fixed;"  fxHide  fxShow.gt-md class="header-v2 app-toolbar primary" fxLayoutAlign="space-between center">
    <mat-toolbar-row style="height: 100%;"  fxLayoutAlign="space-between center" class="theme-container">
  
                <div fxLayout="row" fxLayoutGap="10px" >
  
  
                    <section>
                        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
  
                    </section>
                    <div class="search-bar" fxLayoutGap="10px" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="center center"
                        fxFlex fxFlex.gt-sm="500px">
  
                        <calsoft-search [scroll]="false" fxLayoutAlign="center center"></calsoft-search>
                    </div>
                </div>
  
                <div fxHide fxShow.gt-sm class="list-flex user-tool">
                  <calsoft-call [data]="data"></calsoft-call>
                    <calsoft-language *ngIf="languageSelection==1" style="color: #ffffff"></calsoft-language>
                    <calsoft-cart-icon [color]="false" ></calsoft-cart-icon>
  
  
                    <div *ngIf="!userLoggedIn"(click)="SignIn('login')" fxLayoutAlign="start center">
                        <button mat-flat-button class="primary" style="color: #ffffff" *ngIf="!userLoggedIn">LOGIN/SIGNUP
                        </button>
                    </div>
  
                    <calsoft-HeaderUserProfileDropdown [data]="data" *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                </div>
              </mat-toolbar-row>
  
  </mat-toolbar>
  <mat-toolbar fxHide fxShow.gt-md >
  
  
  
  <mat-toolbar-row  fxLayout="row" fxLayoutAlign="center center"
        style=" background: #fff;margin-top: 90px;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);padding: 0px;color: #000000;height: -webkit-fill-available"
        class="header-v2-bottom main-menu-wrap theme-container">
  
        <calsoft-Menu  [data]="data" [type]="'one'" [categoryArray]="item"></calsoft-Menu>
  
  </mat-toolbar-row >
    </mat-toolbar>
    </div>
    <div *ngIf="!display">
    
      <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header>
    </div>
  
    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header-new>
    </div>
  
    </div>
  <!-- Mobile View  end -->
  
  
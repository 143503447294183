import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {


  constructor(private auth: UserService,
    private router: Router,
    private dialog: MatDialog,
    ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let basicAuthHeaderString = this.auth.getAuthenticatedToken();
    let username = this.auth.getAuthenticatedUser();

    if (basicAuthHeaderString ) {
      return true;
    } else {
     this.router.navigate(['/']);
      return false;
    }
  }


}

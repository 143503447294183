import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalsoftTrimDirective } from './calsoft-trim.directive';
import { LazyImgDirective } from './lazy-img.directive';
import { NumberDirective } from './number.directive';



@NgModule({
  declarations: [CalsoftTrimDirective, LazyImgDirective, NumberDirective],
  imports: [
    CommonModule
  ],
  exports: [CalsoftTrimDirective,LazyImgDirective,NumberDirective]
})
export class DirectiveModule { }

<!-- 
<div *ngIf="flags.length>0">
    <div style="cursor: poiter" fxLayout="row" class="pointer" fxLayoutAlign="start center" fxLayoutGap="2px" mat-button
    [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
    <span style="font-size: 16px"  >{{flag.languageCode | uppercase}}</span>
    <mat-icon class="mat-icon-sm caret icon_color">arrow_drop_down</mat-icon>
</div>
<mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
    <span (mouseleave)="langMenuTrigger.closeMenu()">
        <button fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" mat-menu-item
            *ngFor="let flag of flags let i=index;" (click)="changeLang(flag,i)">
            {{flag.languageCode|uppercase}}
        </button>
    </span>
</mat-menu>
</div> -->

<div  *ngIf="type=='normal';">
<div style="cursor: poiter" fxLayout="row" class="pointer" fxLayoutAlign="start center" fxLayoutGap="2px" mat-button
    [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger">
    <span style="font-size: 16px"  >{{flag.locale |uppercase}}</span>
    <mat-icon class="mat-icon-sm caret icon_color">arrow_drop_down</mat-icon>
</div>
<mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
    <span (mouseleave)="langMenuTrigger.closeMenu()">
        <button fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" mat-menu-item
            *ngFor="let flag of flags let i=index;" (click)="changeLang(flag,i)">
            {{flag.string |uppercase}} - {{flag.locale |uppercase}}
        </button>
    </span>
</mat-menu>
</div>
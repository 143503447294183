import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from 'src/app/Core/menu/menu-items/menu-items';
import { NavService } from 'src/app/services/nav.service';


@Component({
  selector: 'app-menu3',
  templateUrl: './menu3.component.html',
  styleUrls: ['./menu3.component.scss']
})
export class Menu3Component implements OnInit {

  @Input() categoryArray: any;
  public menuItems: any[] = [];

  constructor(private router: Router, public navServices: NavService) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }
  ngOnInit(): void {

    //console.log(this.categoryArray);
    
  }
  
  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  
  // Click Toggle menu (Mobile)
  toggletNavActive(item: any) {
    item.active = !item.active;
  }




}

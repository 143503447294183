import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitbyHash'
})
export class SplitbyHashPipe implements PipeTransform {

  transform(value: string): string {
 
    let  splittedTextList:any =  value.split('#');
    
    let splittedText=splittedTextList[0];

    return splittedText;
  }
}



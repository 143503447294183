<div *ngIf="display">
<header class="header header-6 theme-container" fxHide fxShow.gt-md>
    <div class="header-top" >
        <div [class]="containerClass">
            <div class="header-left" style="cursor: pointer;" (click)="callPhone()" *ngIf="data.showContactNumber==1" >
                <mat-icon class="icon-css" >call</mat-icon>Call: {{data.storePhoneNumber}}
            </div>
            <div class="header-right">
                <div *ngIf="showFooterIcon==1" >         
                    <div>
                        <span *ngFor="let item of footerIconData; let i = index" >
                       
                      <button (click)="navigate(item)" mat-icon-button >
                     
                      <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                    </button>
                    </span>
                   
                  </div>
                 
                </div>
                
                <div style = "cursor:pointer ;font-size: 12px;"  fxLayoutAlign="center center" >

                    <div class="login padding-10" *ngIf="!userLoggedIn"(click)="SignIn('login')">Login</div>

                    <calsoft-HeaderUserProfileDropdown [data]="data"  *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
    
                            
                    <div  class="login padding-10" style="font-size:12px"   *ngIf="languageSelection==1">
                        <calsoft-language ></calsoft-language>
                    </div>

                </div>

               
            </div>
        </div>
    </div>

    <div class="header-middle" >
        <div class="containerCss">
            <div class="container">
                <div class="header-left">
                <div class="search-bar" fxLayoutGap="10px" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="center center"
                fxFlex fxFlex.gt-sm="350px">

                <calsoft-search [scroll]="false" [searchbarborderRadius]="'15px'" fxLayoutAlign="center center"></calsoft-search>
                </div>
               </div>
            <div class="header-center">
                <calsoft-logo  [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

            </div>
                <div class="header-right">

                    <button class="true" (click)="wishlist()" mat-icon-button matBadgePosition="after" matBadgeColor="accent">
                        <i class="material-icons notranslate">
                            favorite_border
                        </i>
                    </button>

                    <calsoft-cart-icon [color]="true" ></calsoft-cart-icon>

                </div>
            </div>
        </div>
    </div>

    <div  fxLayoutAlign="center center" class="container">

        <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'" [categoryArray]="item"></calsoft-Menu>
    </div>
    <mat-toolbar fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header primary" fxLayout="row"
    fxLayoutAlign="space-between center" style="z-index: 1000;">
    <mat-toolbar-row class=" theme-container" style="height: 100%;" fxLayoutAlign="space-between center">
    <section class="padding-10" style="padding-left: 7%;">
        <calsoft-logo  [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

    </section>
    <section>
        <div class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center" fxFlex
            fxFlex.gt-sm="500px">
            <calsoft-search [searchbarborderRadius]="'15px'"></calsoft-search>
        </div>
    </section>
    <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
      
        <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
            <div style="color: #ffffff" fxLayout="column">
                <small>Hello, Sign in</small>
                <span>Account & Lists</span>
            </div>
        </button>
    
        <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser" *ngIf="userLoggedIn">
        </calsoft-HeaderUserProfileDropdown>

        <calsoft-cart-icon [color]="false"></calsoft-cart-icon>
    </section>
    </mat-toolbar-row>

</mat-toolbar>
</header>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header-new>
    </div>
    
</div>

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';



@Component({
  selector: 'calsoft-menu2',
  templateUrl: './menu2.component.html',
  styleUrls: ['./menu2.component.scss']
})
export class Menu2Component implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('menu', { static: true }) menu: MatMenu;
  @Input() items$: Observable<{ name: string; children$: Observable<string[]> }[]>;
  @Input() display: any=false;
  
  @Input() data: any;
  constructor(   private commonService: CommonService,) { }

  ngOnInit(): void {
  }
  getDouble(children$: Observable<string[]>): Observable<string[]> {
    return children$.pipe(map(children => [...children, ...children]));
  }

  goToCategory(menu) {

    

    this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);

  }

  closeMyMenu(){
    this.trigger.closeMenu();
   // console.log('close')
  }

  timedOutCloser;

  @ViewChild('clickHoverMenuTrigger') clickHoverMenuTrigger: MatMenuTrigger;

  mouseEnter() {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    this.clickHoverMenuTrigger.openMenu();
  }

  mouseLeave() {
    this.timedOutCloser = setTimeout(() => {
      this.clickHoverMenuTrigger.closeMenu();
    }, 50);
  }


}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UrlService {
  public previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();


  private breadCrumpUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public breadCrumpUrl$: Observable<string> = this.breadCrumpUrl.asObservable();


  constructor() { }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }

  setBreadCrumpId(breadCrumpUrl:string){
    this.breadCrumpUrl.next(breadCrumpUrl);
  }

}

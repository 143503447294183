import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {

  googleMaps: any;
  private _places = new BehaviorSubject<any[]>([]);
  private _markerChange = new BehaviorSubject<any>({});
  private BroadCastMsg = new Subject<any>();

  private readonly apiUrl = 'https://maps.googleapis.com/maps/api/js';
  

  constructor(private http: HttpClient, private zone: NgZone) {

  }

  get places() {
    return this._places.asObservable();
  }

  get markerChange() {
    return this._markerChange.asObservable();
  }


  loadGoogleMaps(apikey:any): Promise<any> {
    const win = window as any;
    const gModule = win.google;
    if(gModule && gModule.maps) {
     return Promise.resolve(gModule.maps);
    }
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=' +
        apikey
         + '&libraries=places';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => {
        const loadedGoogleModule = win.google;
        if(loadedGoogleModule && loadedGoogleModule.maps) {
          resolve(loadedGoogleModule.maps);
        } else {
          reject('Google Map SDK is not Available');
        }
      };
    });
  }

  getAddress(lat: number, lng: number,apikey:any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apikey}`
        )
        .pipe(
          map(geoData => {
            if(!geoData || !geoData.results || geoData.results.length === 0) throw(null);
            return geoData.results[0];
          })
        ).subscribe(data => {
          resolve(data);
        }, e => {
          reject(e);
        });
    });
  }

  async getPlaces(query,apikey) {
    try {
      if(!this.googleMaps) {
        this.googleMaps = await this.loadGoogleMaps(apikey);
      }
      let googleMaps: any = this.googleMaps;

      let service = new googleMaps.places.AutocompleteService();
      service.getPlacePredictions({
        input: query,
        componentRestrictions: {
          country: 'IN'
        }
      }, (predictions) => {
        let autoCompleteItems = [];
        this.zone.run(() => {
          if(predictions != null) {
            predictions.forEach(async(prediction) => {
              console.log('prediction: ', prediction);
              let latLng: any = await this.geoCode(prediction.description, googleMaps);
              const places = {
                title: prediction.structured_formatting.main_text,
                address: prediction.description,
                lat: latLng.lat,
                lng: latLng.lng
              };
              console.log('places: ', places);
              autoCompleteItems.push(places);
            });
            // rxjs behaviorSubject
            this._places.next(autoCompleteItems);
          }
        });
      });
    } catch(e) {
      console.log(e);
    }
  }

  geoCode(address, googleMaps) {
    let latlng = {lat: '', lng: ''};

    try {
      return new Promise((resolve, reject) => {
        let geocoder = new googleMaps.Geocoder();
        geocoder.geocode({'address' : address}, (results) => {
          console.log('results: ', results);
          latlng.lat = results[0].geometry.location.lat();
          latlng.lng = results[0].geometry.location.lng();
          resolve(latlng);
        });
      })
      
    } catch (error) {
      console.log(error);
      
    }
   
  }

  changeMarkerInMap(location) {
    this._markerChange.next(location);
  }

  sendDataPost = (data:any) => {
    this.BroadCastMsg.next(data);
  }

  getObservable = (): Subject<any> => {
    return this.BroadCastMsg;
  }

  getDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    const dLat = this.degToRad(lat2 - lat1);
    const dLon = this.degToRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.degToRad(lat1)) * Math.cos(this.degToRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const roundedValue = earthRadius * c;
    const distance = Math.round(roundedValue * 100) / 100;

    return distance;
  }

  degToRad(deg: number): number {
    return deg * (Math.PI / 180);
  }



  // loadApi(): Promise<void> {
  //   const script = document.createElement('script');
  //   script.src = `${this.apiUrl}?key=${this.apiKey}`;
  //   script.type = 'text/javascript';
  //   script.async = true;
  //   script.defer = true;

  //   return new Promise<void>((resolve, reject) => {
  //     script.onload = () => {
  //       resolve();
  //     };

  //     script.onerror = () => {
  //       reject(new Error('Failed to load Google Maps API'));
  //     };

  //     document.body.appendChild(script);
  //   });
  // }/

}

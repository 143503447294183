import { Component , Inject} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ConfigService } from '../services/config.service';
import { ConfigServiceService } from '../services/config-service.service';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent {


  constructor(private router: Router,
    private configService:ConfigServiceService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { mobileType: 'ios' | 'android', promptEvent?: any },
    private bottomSheetRef: MatBottomSheetRef<PromptComponent>
  ) {}
  spinnerEnabled:any=0;
  
  public installPwa(): void {
    let url = this.router.url;

    if (url.includes('home')) {
      this.data.promptEvent.prompt();
      this.close();
    }else{
      this.close();
    }
  }

  public close() {
    this.bottomSheetRef.dismiss();
  }
  
  // getConfigData() {
  //   this.configService.getConfigData()
  //     .subscribe(
  //       data => {

  //         this.spinnerEnabled=this.data['spinnerEnabled']
  
  //       }, error => {
  
  //       }
  //     );
  // }

}

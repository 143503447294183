import { Injectable } from '@angular/core';
import { Constant } from 'src/app/constants/constant';

/*
 * Menu interface
 */
export interface Menu {
  state: string;
  name?: string;
  type?: string;
  icon?: string;
  children?: Menu[];
}

const HeaderOneItems = [
  {
    state: "home",
    name: "HOME",
    type: "sub",
    icon: "home",
    children: [
      {
        state: 'home',
        name: 'HOME ONE',
        type: 'link',
        icon: 'home'
      },
      {
        state: 'home-two',
        name: 'HOME TWO',
        type: 'link',
        icon: 'home'
      }, {
        state: 'home-three',
        name: 'HOME THREE',
        type: 'link',
        icon: 'home'
      }
    ]
  },
  {
    state: "",
    name: "SHOP",
    type: "sub",
    icon: "pages",
    children: [
      {
        state: 'products/men/4',
        name: 'PRODUCT DETAILS',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'cart',
        name: 'CART',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'checkout',
        name: 'CHECKOUT',
        type: 'link',
        icon: 'arrow_right_alt'
      },
      {
        state: 'checkout/payment',
        name: 'PAYMENT',
        type: 'link',
        icon: 'arrow_right_alt'
      }
    ]
  },


  {
    state: 'products/accessories',
    name: "ACCESSORIES",
    type: "link",
    icon: 'party_mode'
  },
  {
    state: 'products',
    name: "CATEGORIES",
    type: "sub",
    mega: true,
    icon: 'party_mode',
    children: [
      {
        state: 'men',
        name: 'MEN',
        type: 'sub',
        icon: 'arrow_right_alt',
        children: [
          {
            state: 'products/men',
            queryState: 'Jeans',
            name: 'JEAN',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/men',
            queryState: 'Jackets',
            name: 'JACKETS',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/men',
            queryState: 'Shirt',
            name: 'SHIRT',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/men',
            queryState: 'T-Shirt',
            name: 'T-SHIRT',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          }
        ]
      },
      {
        state: 'woman',
        name: 'WOMEN',
        type: 'sub',
        icon: 'arrow_right_alt',
        children: [
          {
            state: 'products/woman',
            queryState: 'Dresses',
            name: 'DRESS',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/woman',
            queryState: 'Shirt',
            name: 'SHIRT',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/woman',
            queryState: 'T-Shirt',
            name: 'T-SHIRT',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          }
        ]
      },
      {
        state: 'gadgets',
        name: 'GADGETS',
        type: 'sub',
        icon: 'arrow_right_alt',
        children: [
          {
            state: 'products/gadgets',
            queryState: 'Headphone',
            name: 'HEADPHONE',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/gadgets',
            queryState: 'Smartphone',
            name: 'SMARTPHONE',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/gadgets',
            queryState: 'Watch',
            name: 'WATCH',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/gadgets',
            queryState: 'Speaker',
            name: 'SPEAKER',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          }
        ]
      },
      {
        state: 'accessories',
        name: 'ACCESSORIES',
        type: 'sub',
        icon: 'arrow_right_alt',
        children: [
          {
            state: 'products/accessories',
            queryState: 'Laptap',
            name: 'LAPTOP ACCESSORIES',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/accessories',
            queryState: 'Belts',
            name: 'BELTS',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          },
          {
            state: 'products/accessories',
            queryState: 'Jewellery',
            name: 'JEWELLERY',
            type: 'queryParams',
            icon: 'arrow_right_alt',
          }
        ]
      }
    ]
  },
  {
    state: "pages",
    name: "PAGES",
    type: "sub",
    icon: "pages",
    children: [
      {
        state: 'about',
        name: 'ABOUT',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'term-condition',
        name: 'TERM AND CONDITION',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'privacy-policy',
        name: 'PRIVACY POLICY',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'blogs/detail',
        name: 'BLOG DETAIL',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'faq',
        name: 'FAQ',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'not-found',
        name: '404 PAGE',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'account/profile',
        name: 'User Profile',
        type: 'link',
        icon: 'arrow_right_alt',
      }
    ]
  },
  {
    state: 'session',
    name: "HELP",
    type: "sub",
    icon: 'supervised_user_circle',
    children: [
      {
        state: 'session/signin',
        name: 'SIGN IN',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'session/signup',
        name: 'REGISTER',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'session/forgot-password',
        name: 'FORGET PASSWORD',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'session/thank-you',
        name: 'THANK YOU',
        type: 'link',
        icon: 'arrow_right_alt',
      }
    ]
  },
];

const FooterOneItems = [
  {
    state: '',
    name: Constant.siteName,
    type: "sub",
    icon: '',
    children: [
      {
        state: 'aboutus',
        name: 'About us',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'privacy',
        name: 'Privacy Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'shippingPolicy',
        name: 'Shipping Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'returnPolicy',
        name: 'Refund / Cancellation Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'terms&conditions',
        name: 'Terms & Conditions',
        type: 'link',
        icon: 'arrow_right_alt',
      },

       {
        state: 'coupon',
        name: 'Coupon',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'contact-Us',
        name: 'Contact-Us',
        type: 'link',
        icon: 'arrow_right_alt',
      },
        {
        state: 'paymentPolicy',
        name: 'Payment-Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'brochure',
        name: 'Brochure',
        type: 'link',
        icon: 'arrow_right_alt',
      },
    ]
  },


]

const FooterTwoItems = [
  {
    state: '',
    name: Constant.siteName,
    type: "sub",
    icon: '',
    children: [
      {
        state: 'aboutus',
        name: 'About us',
        type: 'link',
        icon: 'arrow_right_alt',
      },
    
      {
        state: 'privacy',
        name: 'Privacy Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'shippingPolicy',
        name: 'Shipping Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'returnPolicy',
        name: 'Refund / Cancellation Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
    
      {
        state: 'terms&conditions',
        name: 'Terms & Conditions',
        type: 'link',
        icon: 'arrow_right_alt',
      },
  
       {
        state: 'coupon',
        name: 'Coupon',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'paymentPolicy',
        name: 'Payment-Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'brochure',
        name: 'Brochure',
        type: 'link',
        icon: 'arrow_right_alt',
      },
   
    ]
  },


]

const FooterFourthItems = [
  {
    state: '',
    name: Constant.siteName,
    type: "sub",
    icon: '',
    children: [
      {
        state: 'aboutus',
        name: 'About us',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      
      {
        state: 'privacy',
        name: 'Privacy Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'shippingPolicy',
        name: 'Shipping Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'returnPolicy',
        name: 'Refund / Cancellation Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'terms&conditions',
        name: 'Terms & Conditions',
        type: 'link',
        icon: 'arrow_right_alt',
      },

       {
        state: 'coupon',
        name: 'Coupon',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'contact-Us',
        name: 'Contact-Us',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'blog',
        name: 'Blog',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'paymentPolicy',
        name: 'Payment-Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'brochure',
        name: 'Brochure',
        type: 'link',
        icon: 'arrow_right_alt',
      },
    ]
  },


]

const FooterThirdItems = [
  {
    state: '',
    name: Constant.siteName,
    type: "sub",
    icon: '',
    children: [
      {
        state: 'aboutus',
        name: 'About us',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'privacy',
        name: 'Privacy Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'shippingPolicy',
        name: 'Shipping Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'returnPolicy',
        name: 'Refund / Cancellation Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'terms&conditions',
        name: 'Terms & Conditions',
        type: 'link',
        icon: 'arrow_right_alt',
      },

       {
        state: 'coupon',
        name: 'Coupon',
        type: 'link',
        icon: 'arrow_right_alt',
      },
  
      {
        state: 'blog',
        name: 'Blog',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'paymentPolicy',
        name: 'Payment-Policy',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'brochure',
        name: 'Brochure',
        type: 'link',
        icon: 'arrow_right_alt',
      },
    ]
  },


]

@Injectable({
  providedIn: 'root'
})
export class MenuItems {

  /*
   * Get all header menu
   */
  getMainMenu(): Menu[] {
    return HeaderOneItems;
  }

  /*
   * Get all footer menu
   */
  getFooterOneMenu(): Menu[] {
    return FooterOneItems;
  }

  getFooterTwoMenu(): Menu[] {
    return FooterTwoItems ;
  }

  
  getFooterThirdMenu(): Menu[] {
    return FooterThirdItems ;
  }

  
  getFooterFourthMenu(): Menu[] {
    return FooterFourthItems ;
  }

}

import { Component,Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'calsoft-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  @Input() type: string="normal";
  index: any;
  countryCode: Subject<any> = this.loaderService.countryCode;

  constructor(
    private userService: UserService,
    private loaderService: LoaderService,
    private router: Router,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.index = this.commonService.getLocaleIndex();
    if (this.index == null) {
      let i = 0;
      this.flag = this.flags[i];
    }
    else {
      this.flag = this.flags[this.index];
    }
  }


  public flags: any = [
    {
      "locale": "en",
      "string": "English",
    },
    {
      "locale": "ta",
      "string": "Tamil",
    },
  ]




  public flag: any;

  public changeLang(flag, i) {
    this.flag = flag;
    this.commonService.setLanguageLocale(flag.locale, i);

    this.countryCode.observers = [];
    this.countryCode.observers.push(flag)
    this.userService.reload();



  }



}

<div class="main-navbar theme-container">
    <div id="mainnav">
      <div class="toggle-nav" (click)="mainMenuToggle()">
        <i class="fa fa-bars sidebar-bar"></i>
      </div>
      <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

        <li class="back-btn">
          <div class="mobile-back text-end" (click)="mainMenuToggle()">
            <span>Back</span>
            <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
          </div>
        </li>
  
        <li *ngFor="let menuItem of categoryArray"
         [class.mega-menu]="menuItem.mega=='true'?true:false">
          <!-- Sub -->
          <a href="javascript:void(0)" class="nav-link" 
         
              (click)="toggletNavActive(menuItem)">
            {{ menuItem.categoryName  }}
            <div class="lable-nav" *ngIf="menuItem.badge">
                {{ menuItem.categoryName }}</div>
            <!-- <span  *ngIf="menuItem.children"></span> -->
            <mat-icon *ngIf="menuItem.subcategories.length > 0" 
                  class="sub-arrow1">expand_more</mat-icon> 
          </a>
  
          <!-- 2nd Level Menu -->
          <ul class="nav-submenu" [class.opensubmenu]="menuItem.isActive=='1'?true:false"
           *ngIf="menuItem.subcategories">
            <li *ngFor="let childrenItem of menuItem.subcategories">
              <!-- Sub -->
              <a href="javascript:void(0)" 
                  (click)="toggletNavActive(childrenItem)">
                {{ childrenItem.categoryName }}
               
                <span class="sub-arrow" *ngIf="childrenItem.subcategories"></span>
              </a>
        
  
              <!-- 3rd Level Menu -->
              <ul *ngIf="childrenItem.subcategories" [class.opensubchild]="childrenItem.isActive=='1'?true:false"
              class="nav-sub-childmenu">
                <li *ngFor="let childrenSubItem of childrenItem.subcategories">
                  <!-- Link -->
                  <a 
                   >
                    {{ childrenSubItem.categoryName }}

                  </a>
                
                </li>
              </ul>
            </li>
          </ul>
  
          <div class="mega-menu-container" *ngIf="menuItem.mega=='true'?true:false && menuItem.subcategories">
            <div class="container">
              <div class="row">
                <div class="col mega-box" *ngFor="let childrenItem
                 of menuItem.subcategories">
                  <div class="link-section">
                    <div class="menu-title">
                      <h5>
                          {{ childrenItem.categoryName }}
                       
                      </h5>
                    </div>
                    <div class="menu-content opensubmegamenu">
                      <ul *ngIf="childrenItem.children">
                        <li *ngFor="let childrenSubItem of childrenItem.subcategories">
                          <!-- Sub -->
                            <a href="javascript:void(0)">
                              {{ childrenSubItem.categoryName }}
                            
                            </a>
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  

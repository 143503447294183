<div class="appLogo pointer gap" fxFlex="100" fxLayoutAlign="center center">

  <div *ngIf="type=='dark'; else normalImage">
    <a (click)="closeMenu()">

      <img class="isa" fxFlex="100" fxLayoutAlign="center center"
      onerror="this.src='assets/images/error/logoerror1.png';  this.style.width='80px';"
      [src]="serverPath+'logo.png'" [alt]="sitename">
 
  </a>


</div>


  <ng-template #normalImage>

    <a (click)="closeMenu()">
      <img class="isa"  fxFlex="100" fxLayoutAlign="center center"  onerror="this.src='assets/images/error/logoerror1.png';  this.style.width='80px';"
          [src]="serverPath+'logoBG.png'" [alt]="sitename">
  </a>
    </ng-template>

  </div>
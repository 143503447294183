import { Component, HostListener, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Constant } from 'src/app/constants/constant';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CartService } from 'src/app/services/cart.service';
import { CommonService } from 'src/app/services/common.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Search2Component } from '../../search/search2/search2.component';
declare var $: any;



@Component({
  selector: 'calsoft-header11',
  templateUrl: './header11.component.html',
  styleUrls: ['./header11.component.scss']
})
export class Header11Component implements OnInit {
  title: any;
  public siteName = Constant.siteName
  @Input() class!: string;
  @Input() languageSelection: any;
  @Input() item: any;
  @Input() data: any;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  public serverPath = environment.commonImageApi+"logo/";
  toggleActive: boolean = false;
  cartProducts: any;
  popupResponse: any;
  wishlistProducts: any;
  userLoggedIn: boolean;
  page: string;
  page1: any;
  cartTotalItems: any;
  public form: UntypedFormGroup;
  url: string;
  countryCode: Subject<any> = this.loaderService.countryCode;
  index: any;
  logo: Object;
  showLeftMenu:any=0;
  display: boolean;
  public stick: boolean = false;
  mobileHeaderType:any=0;

  constructor(
    public calsoftService: CalsoftService,
      private router: Router,
      private basicAuthenticationService: UserService,
      private dialog: MatDialog,
      private userService: UserService,
      private fb: UntypedFormBuilder,
      public cartServices: CartService,
      private loaderService: LoaderService,
      private location: Location,
      private commonService: CommonService
  ) { }




  ngOnInit(): void {

   // console.log(this.item);
    this.userLogged();
      this.url = this.router.url;
    
      if (window.innerWidth < 1281) {
         this.display = false;
       }else{
         this.display=true
       }

       this.mobileHeaderType=this.data['mobileHeaderType'];

       
    
  }


  callPhone() {
   let phoneNumber = "tel:" + this.data.storePhoneNumber;
   window.location.href = phoneNumber;
 }
  
  
  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

  userLogged() {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
       return false;
    };

    let basicAuthHeaderString = this.basicAuthenticationService.getAuthenticatedToken();
    let username = this.basicAuthenticationService.getAuthenticatedUser();



    if (basicAuthHeaderString && username) {

       this.userLoggedIn = true;
       this.cartServices.getCount();

    }
    else {

       this.userLoggedIn = false;
    }
 }




 loginModel() {
    if (window.innerWidth > 768) {

       let dialogRef = this.dialog.open(CommonSignInComponent, {
          width: '800px',
          data: { page: this.page1, }
       });

       dialogRef.disableClose = true;
       dialogRef.afterClosed().subscribe(result => {
          this.ngOnInit();


       });

    }
    else {
       let dialogRef = this.dialog.open(CommonSignInComponent, {
          minWidth: '100vw', height: '100vh',
          data: { page: this.page1, }
       });

       dialogRef.disableClose = true;
       dialogRef.afterClosed().subscribe(result => {
          this.ngOnInit();


       });
    }

 }

 back() {
    this.location.back();
 }

 wishlist() {
    this.router.navigate(['/account/wishlist']);
 }

 SignIn() {
    // this.router.navigate(['/sign-in']);
    this.loginModel();
 }

 home() {
    this.router.navigate(['/'])
 }


 search() {
    this.router.navigate(['/search'])
 }

 public toggleSearch() {
    $('app-main').toggleClass('form-open');
 }

 public toggleSidebar() {
    this.calsoftService.sidenavOpen = !this.calsoftService.sidenavOpen;
 }



 public selectedCurrency(value) {
    this.calsoftService.currency = value;
 }

 public selectedLanguage(value) {
    this.calsoftService.language = value;
 }

 getClass(i) {
    if (i == '/checkout') return 'pr50'
    else if (i == '/cart') return 'pr50'
 }

 searchPopup() {
   
   let dialogRef = this.dialog.open(Search2Component, {
     minWidth: '40vw', height: '40vh',
     data: { page: 'address', }
   });

   dialogRef.disableClose = true;
   dialogRef.afterClosed().subscribe(result => {
     
   });
 

}


}

<!-- <div fxLayout="row"  class="padding-5 primary">
<mat-toolbar class="search padding-10 primary"  fxLayoutAlign="end center" >
    <button (click)="close()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<div class="title" fxLayout="column" fxLayoutAlign="center center" > 
    <span>Search</span>
    
    
    <div  class="search-wrapper text-muted" fxLayout="row"
            [ngClass]="{ 'focus': focused, 'has-input': input }" fxFlex (vrClickOutside)="closeDropdown()">
            <mat-icon *ngIf="search=='' || search==null" class="search-icon">search</mat-icon>
            <input type="search" autocomplete="off" spellcheck="false" class="search-input text-muted" [(ngModel)]="search"
                (input)="filterOption($event)" (keyup.enter)="onKeydown($event)" (focus)="openDropdown()"
                placeholder="Search..." #inputElem [matAutocomplete]="auto">
            <mat-autocomplete  class="mat-elevation-z2 autocmp" #auto="matAutocomplete">
                <mat-option  *ngFor="let filter of filteredOptions" (keydown.enter)="getProducts(filter.name)"
                    (click)="getProducts(filter.name)" [value]="filter.name">
                    <mat-icon>search</mat-icon>
        
                    <span>{{ filter.name }}</span>
                </mat-option>
        
            </mat-autocomplete>
        </div>
      </div> -->


      <div class="index-head">
        <span>Search</span>
     </div>
     <div class="padding-index">
      <div  class="search-wrapper text-muted" fxLayout="row"
      [ngClass]="{ 'focus': focused, 'has-input': input }" fxFlex (vrClickOutside)="closeDropdown()">
      <mat-icon *ngIf="search=='' || search==null" class="search-icon">search</mat-icon>
      <input type="search" autocomplete="off" spellcheck="false" class="search-input text-muted" [(ngModel)]="search"
          (input)="filterOption($event)" (keyup.enter)="onKeydown($event)" (focus)="openDropdown()"
          placeholder="Search..." #inputElem [matAutocomplete]="auto">
      <mat-autocomplete  class="mat-elevation-z2 autocmp" #auto="matAutocomplete">
          <mat-option  *ngFor="let filter of filteredOptions" (keydown.enter)="getProducts(filter.name)"
              (click)="getProducts(filter.name)" [value]="filter.name">
              <mat-icon>search</mat-icon>
  
              <span>{{ filter.name }}</span>
          </mat-option>
  
      </mat-autocomplete>
  </div>
     </div>
    
    
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
@Injectable({
	providedIn: 'root'
})
export class CategoryService {

	constructor(private http: HttpClient) { }
	public responseCache = new Map();

	url = environment.serverAPI + '/api/catalog/categories';

	categoryById(id: any, type: any): Observable<Object> {
		return this.http.get(`${this.url}/breadCrump/${type}/new?categoryName=${id}`);
	}

	public categoryList(): Observable<any> {
		
		const categoryFromCache = this.responseCache.get(`${this.url}/idx/nestedPostion`);

		if (categoryFromCache) {
		  return of(categoryFromCache); // Return cached data as an Observable
		} else {
		  return this.http.get<any>(`${this.url}/idx/nestedPostion`).pipe(
			tap(category => this.responseCache.set(`${this.url}/idx/nestedPostion`, category))
		  );
		}
		
	}

}
